import React, { createContext, useContext, useState } from 'react'
import { useRetailerId, useStoreConfigId } from '@retailer-platform/dashboard/routing'

type CurrentScopeContextType = {
  retailerIds: string[] | undefined
  storeConfigIds: string[] | undefined
  updateRetailerIds: (ids: string[] | undefined) => void
  updateStoreConfigIds: (ids: string[] | undefined) => void
}

type CurrentRetailerScope = {
  retailerId: string | undefined // can be undefined in v2 if not set in storefront field
  retailerIds: string[] | undefined
}

type CurrentStoreConfigScope = {
  storeConfigId: string | undefined // can be undefined in v2 if not set in storefront field
  storeConfigIds: string[] | undefined
}

const CurrentScopeContext = createContext<CurrentScopeContextType>({
  retailerIds: undefined,
  storeConfigIds: undefined,
  updateRetailerIds: () => {},
  updateStoreConfigIds: () => {},
})

export const CurrentScopeProvider = ({ children }: { children: React.ReactNode }) => {
  const [retailerIds, setRetailerIds] = useState<string[] | undefined>(undefined)
  const [storeConfigIds, setStoreConfigIds] = useState<string[] | undefined>(undefined)

  return (
    <CurrentScopeContext.Provider
      value={{
        retailerIds,
        storeConfigIds,
        updateRetailerIds: setRetailerIds,
        updateStoreConfigIds: setStoreConfigIds,
      }}
    >
      {children}
    </CurrentScopeContext.Provider>
  )
}

export const useCurrentRetailerScope = (): CurrentRetailerScope => {
  const routeRetailerId = useRetailerId({ throwOnMissing: false }) // from route
  const { retailerIds: contextRetailerIds } = useCurrentScopeContext() // from context (updated from form values)
  const retailerId = routeRetailerId ?? contextRetailerIds?.[0]

  return {
    retailerId,
    retailerIds: contextRetailerIds,
  }
}

export const useCurrentStoreConfigScope = (): CurrentStoreConfigScope => {
  const routeStoreConfigId = useStoreConfigId({ throwOnMissing: false }) // from route
  const { storeConfigIds: contextStoreConfigIds } = useCurrentScopeContext() // from context (updated from form values)
  const storeConfigId = routeStoreConfigId ?? contextStoreConfigIds?.[0]

  return {
    storeConfigId,
    storeConfigIds: contextStoreConfigIds,
  }
}

export const useCurrentScopeContext = () => useContext(CurrentScopeContext)
