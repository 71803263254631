import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const caperAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.ConnectedStoresView],
}

export const uposAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.UposViewStatus],
}

export const shelfLabelsAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.ShelfLabelsModify],
}
