import React, { useMemo, useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { typography } from '@retailer-platform/shared-components/src/tds'
import {
  Button,
  ModalBody,
  ModalHeader,
  TextArea,
  ModalFooter,
} from '@retailer-platform/shared-components'
import { ButtonArrayComponent } from '../../RetailerSatisfactionSurveyComponents'
import { useDashMessages } from '../../../../../intl/intl.hooks'
import { useSurvey } from '../../RetailerSatisfactionSurveyContext'
import {
  NavV2FeedbackSurveyPages,
  type NavV2FeedbackSurveyState,
  type NavV2FeedbackSurveyProps,
} from '../NavV2FeedbackSurveyController'
import { trackEvent } from '../../../../../utils/events/trackEvent'
import { useNavV2FeedbackSurveyStorage } from '../useNavV2FeedbackSurveyAccess'

const SurveySubheading = styled.div({
  textAlign: 'left',
  ...typography.bodyEmphasized,
})

const SurveySubheadingLabel = styled.span(({ theme }) => ({
  ...typography.bodyRegular,
  paddingLeft: '2px',
  color: theme.colors.systemGrayscale50,
}))

const SurveyInputContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
  marginBottom: '18px',
})

const Page2 = () => {
  const [isTextAreaShown, setIsTextAreaShown] = useState<boolean>(false)
  const [isSubmittingSurvey, setIsSubmittingSurvey] = useState(false)

  const { surveyState, updateSurveyState, submitSurvey, goToPage } = useSurvey<
    NavV2FeedbackSurveyPages,
    NavV2FeedbackSurveyState,
    NavV2FeedbackSurveyProps
  >()
  const { data, setData } = useNavV2FeedbackSurveyStorage()

  const onSubmitSurvey = useCallback(() => {
    submitSurvey()
    trackEvent({
      id: 'app.navV2.feedback_survey.modal.submit',
      description: 'Tracks whenever the NavV2 feedback survey modal is submitted',
      data: {
        page: 2,
      },
    })
    setData({
      ...data,
      hasSubmittedSurvey: true,
    })
    goToPage(NavV2FeedbackSurveyPages.Page3)
  }, [submitSurvey, setData, data, goToPage])

  useEffect(() => {
    if (isSubmittingSurvey) {
      onSubmitSurvey()
    }
  }, [isSubmittingSurvey, onSubmitSurvey])

  const messages = useDashMessages({
    title: 'navV2.userSurvey.page2.title',
    subheading: 'navV2.userSurvey.page2.subheading1',
    option1: 'navV2.userSurvey.page2.option1',
    option2: 'navV2.userSurvey.page2.option2',
    option3: 'navV2.userSurvey.page2.option3',
    option4: 'navV2.userSurvey.page2.option4',
    option5: 'navV2.userSurvey.page2.option5',
    subheading2: 'navV2.userSurvey.page2.subheading2',
    subheading2Label: 'navV2.userSurvey.page2.subheading2.label',
    submit: 'navV2.userSurvey.page2.submit',
  })

  const buttons = useMemo(
    () =>
      [
        { option: messages.option1, requireAdditionalFeedback: true },
        { option: messages.option2, requireAdditionalFeedback: true },
        { option: messages.option3, requireAdditionalFeedback: true },
        { option: messages.option4, requireAdditionalFeedback: true },
        { option: messages.option5, requireAdditionalFeedback: false },
      ].map(({ option, requireAdditionalFeedback }) => ({
        text: option,
        onClick: () => {
          if (isSubmittingSurvey) {
            return
          }
          updateSurveyState('toolsNavigationResponse', option)
          // If the text area is shown, the user previously selected an option
          // that requires additional feedback. They'll be able to submit the survey
          // with the submit button at the bottom of the modal
          if (!isTextAreaShown) {
            if (requireAdditionalFeedback) {
              setIsTextAreaShown(true)
            } else {
              setIsSubmittingSurvey(true)
            }
          }
        },
        selected: surveyState.toolsNavigationResponse === option,
      })),
    [
      messages?.option1,
      messages?.option2,
      messages?.option3,
      messages?.option4,
      messages?.option5,
      surveyState?.toolsNavigationResponse,
      isSubmittingSurvey,
      updateSurveyState,
      isTextAreaShown,
    ]
  )

  return (
    <>
      <ModalHeader css={{ lineHeight: '26px' }}>{messages.title}</ModalHeader>
      <ModalBody data-testid="nav-v2-feedback-page-2" css={{ padding: '24px 24px 0px' }}>
        <SurveyInputContainer>
          <SurveySubheading>{messages.subheading}</SurveySubheading>
          <ButtonArrayComponent buttons={buttons} />
        </SurveyInputContainer>
        {isTextAreaShown && (
          <SurveyInputContainer>
            <SurveySubheading>
              {messages.subheading2}
              <SurveySubheadingLabel>{messages.subheading2Label}</SurveySubheadingLabel>
            </SurveySubheading>
            <TextArea
              data-testid="nav-v2-feedback-page-2-text-area"
              onChange={evt =>
                updateSurveyState('toolsNavigationOptionalResponse', evt.currentTarget.value)
              }
              css={{ minHeight: 125, fontSize: 15 }}
              autoFocus
            />
          </SurveyInputContainer>
        )}
      </ModalBody>
      {isTextAreaShown && (
        <ModalFooter>
          <Button data-testid="nav-v2-feedback-page-2-submit-btn" onClick={onSubmitSurvey}>
            {messages.submit}
          </Button>
        </ModalFooter>
      )}
    </>
  )
}

export default Page2
