import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import {
  CheckboxWithLabel,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormFieldError,
  TextArea,
  Button,
} from '@retailer-platform/shared-components'
import { TDS_DEFAULT_COLORS, typography } from '@retailer-platform/shared-components/src/tds'
import { errors } from '../../../../utils/error-handling/errors'
import { useSurvey } from '../RetailerSatisfactionSurveyContext'
import { ButtonsSection } from '../RetailerSatisfactionSurveyComponents'
import { useDashMessages } from '../../../../intl/intl.hooks'
import { trackEvent } from '../../../../utils/events/trackEvent'
import {
  UserFeedbackSurveyPages,
  type UserFeedbackSurveyState,
  type UserFeedbackSurveyProps,
} from './UserFeedbackSurveyController'

const SurveyInputContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
  marginBottom: '18px',
})

const SurveySubheading = styled.div({
  ...typography.bodyEmphasized,
})

// TODO: Switch to TDS Button after mantine upgrade
const FeedbackPrimaryButton = styled(Button)({
  margin: 0,
  height: '40px',
  minWidth: '96px',
  ...typography.bodyEmphasized,
  backgroundColor: TDS_DEFAULT_COLORS.colors.brandPrimaryRegular,
  borderColor: TDS_DEFAULT_COLORS.colors.brandPrimaryRegular,
  '&:hover': {
    borderColor: TDS_DEFAULT_COLORS.colors.brandPrimaryDark,
    backgroundColor: TDS_DEFAULT_COLORS.colors.brandPrimaryDark,
  },
  '&:active': {
    borderColor: TDS_DEFAULT_COLORS.colors.brandPrimaryDark,
    backgroundColor: TDS_DEFAULT_COLORS.colors.brandPrimaryExtraDark,
  },
  '&:disabled': {
    backgroundColor: TDS_DEFAULT_COLORS.colors.systemGrayscale10,
    color: TDS_DEFAULT_COLORS.colors.systemGrayscale30,
    borderColor: TDS_DEFAULT_COLORS.colors.systemGrayscale10,
    cursor: 'not-allowed',
  },
})

const FeedbackSecondaryButton = styled(Button)({
  margin: 0,
  height: '40px',
  minWidth: '96px',
  ...typography.bodyEmphasized,
  backgroundColor: TDS_DEFAULT_COLORS.colors.systemGrayscale00,
  borderColor: TDS_DEFAULT_COLORS.colors.systemGrayscale20,
  color: TDS_DEFAULT_COLORS.colors.brandPrimaryRegular,
  '&:hover': {
    backgroundColor: TDS_DEFAULT_COLORS.colors.brandPrimaryExtraLight,
  },
  '&:active': {
    backgroundColor: TDS_DEFAULT_COLORS.colors.brandPrimaryLight,
  },
})

const UserFeedbackSurveyPage1 = () => {
  const [errorMessage, setErrorMessage] = useState(null)

  const {
    surveyState,
    updateSurveyState,
    submitSurvey,
    isSurveySubmitted,
    resetSurveySubmission,
    error,
    loading,
    goToPage,
    toolProps,
  } = useSurvey<UserFeedbackSurveyPages, UserFeedbackSurveyState, UserFeedbackSurveyProps>()

  const messages = useDashMessages({
    title: 'userFeedback.survey.page1.title',
    textAreaSubheading: 'userFeedback.survey.page1.textArea.subheading',
    textAreaPlaceholder: 'userFeedback.survey.page1.textArea.placeholder',
    checkboxLabel: 'userFeedback.survey.page1.checkbox.label',
    secondaryButtonText: 'userFeedback.survey.page1.secondaryButton',
    primaryButtonText: 'userFeedback.survey.page1.primaryButton',
    errorMessageFallback: 'userFeedback.survey.page1.errorMessage',
  })

  const isButtonDisabled = surveyState.userFeedbackResponse.length === 0

  useEffect(() => {
    if (isSurveySubmitted) {
      goToPage(UserFeedbackSurveyPages.Page2)
      trackEvent({
        id: 'app.userFeedback.survey.submitSuccess',
        description: 'Tracks whenever the user feedback survey is succesfully submitted',
      })
    }
  }, [isSurveySubmitted, goToPage])

  useEffect(() => {
    /**
     * Incase the survey submission fails, we:
     * 1. Show the error message on the form
     * 2. Clear the survey submission status, so the user can try again
     * 3. Log the error for internal tracking
     */
    if (error?.message && !errorMessage) {
      const formattedErrorMessage = `${messages.errorMessageFallback} ${error.message}`

      setErrorMessage(formattedErrorMessage)
      resetSurveySubmission()
      errors.captureException(error, 'User Feedback Survey')
      trackEvent({
        id: 'app.userFeedback.survey.submitError',
        description: 'Tracks whenever an error occurs, when submitting the feedback survey',
      })
    }
  }, [error, error?.message, errorMessage, messages.errorMessageFallback, resetSurveySubmission])

  useEffect(() => {
    /**
     * The errorMessage value is only set when the form failed to submit previously
     * Whenever loading is true, we can clear this value
     */
    if (loading && errorMessage && !error?.message) {
      setErrorMessage(null)
    }
  }, [loading, error?.message, errorMessage])

  const onClickSubmit = useCallback(() => {
    trackEvent({
      id: 'app.userFeedback.survey.submitButton.clicked',
      description: 'Tracks whenever the user feedback submit button is clicked',
    })
    submitSurvey()
  }, [submitSurvey])

  const onClickCancel = useCallback(() => {
    trackEvent({
      id: 'app.userFeedback.survey.cancelButton.clicked',
      description: 'Tracks whenever the user feedback cancel button is clicked',
    })
    toolProps?.onClose()
  }, [toolProps])

  return (
    <>
      <ModalHeader>{messages.title}</ModalHeader>
      <ModalBody>
        <SurveyInputContainer>
          <SurveySubheading>{messages.textAreaSubheading}</SurveySubheading>
          <TextArea
            data-testid="user-feedback-text-area"
            onChange={evt => updateSurveyState('userFeedbackResponse', evt.currentTarget.value)}
            css={{ minHeight: 125, fontSize: 15 }}
            placeholder={messages.textAreaPlaceholder}
            autoFocus
          />
          {errorMessage && <FormFieldError>{errorMessage}</FormFieldError>}
        </SurveyInputContainer>
        <SurveyInputContainer>
          <CheckboxWithLabel
            data-testid="user-feedback-checkbox"
            id={messages.checkboxLabel}
            label={messages.checkboxLabel}
            checked={surveyState.userFeedbackAgreeContact}
            onChange={evt =>
              updateSurveyState('userFeedbackAgreeContact', evt.currentTarget.checked)
            }
          />
        </SurveyInputContainer>
      </ModalBody>
      <ModalFooter>
        <ButtonsSection css={{ marginTop: 0, columnGap: '8px' }}>
          <FeedbackSecondaryButton
            variant="secondary"
            onClick={onClickCancel}
            data-testid="user-feedback-secondary-btn"
          >
            {messages.secondaryButtonText}
          </FeedbackSecondaryButton>
          <FeedbackPrimaryButton
            variant="primary"
            disabled={isButtonDisabled || loading}
            onClick={onClickSubmit}
            data-testid="user-feedback-primary-btn"
          >
            {messages.primaryButtonText}
          </FeedbackPrimaryButton>
        </ButtonsSection>
      </ModalFooter>
    </>
  )
}

export default UserFeedbackSurveyPage1
