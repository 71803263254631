import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { typography } from '@retailer-platform/shared-components/src/tds'
import { ModalBody, ModalHeader } from '@retailer-platform/shared-components'
import { errors } from '../../../../../utils/error-handling/errors'
import { useDashMessages } from '../../../../../intl/intl.hooks'
import { trackEvent } from '../../../../../utils/events/trackEvent'
import ConfirmationCheckmark from '../../../../../../src/assets/ConfirmationCheckmark.png'
import { useSurvey } from '../../RetailerSatisfactionSurveyContext'
import {
  type NavV2FeedbackSurveyPages,
  type NavV2FeedbackSurveyState,
  type NavV2FeedbackSurveyProps,
} from '../NavV2FeedbackSurveyController'
import { useNavV2FeedbackSurveyContext } from '../NavV2FeedbackSurveyContext'

const SurveyConfirmationImage = styled.img({
  width: '64px',
  height: '64px',
  objectFit: 'contain',
  marginBottom: '16px',
})

const SurveyConfirmationContainer = styled.div({
  ...typography.subtitle,
})

const SurveyConfirmationBody = styled.div({
  ...typography.bodyRegular,
})

const Page3 = () => {
  const messages = useDashMessages({
    title: 'navV2.userSurvey.page3.title',
    subheading: 'navV2.userSurvey.page3.subheading',
    body: 'navV2.userSurvey.page3.body',
  })
  const { onClose } = useNavV2FeedbackSurveyContext()

  const { error, isSurveySubmitted } = useSurvey<
    NavV2FeedbackSurveyPages,
    NavV2FeedbackSurveyState,
    NavV2FeedbackSurveyProps
  >()

  useEffect(() => {
    if (error?.message) {
      errors.captureException(error, 'NavV2 Feedback Survey')
      trackEvent({
        id: 'app.navV2.feedback_survey.modal.submit.error',
        description: 'Tracks whenever the NavV2 feedback survey is submitted with an error',
        data: {
          errorMessage: error?.message,
        },
      })
    }
  }, [error, error?.message])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (onClose && isSurveySubmitted) {
        onClose()
      }
    }, 1500)

    return () => clearTimeout(timeout)
  }, [onClose, isSurveySubmitted])

  return (
    <>
      <ModalHeader>{messages.title}</ModalHeader>
      <ModalBody
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          paddingBottom: '38px',
        }}
      >
        <SurveyConfirmationImage src={ConfirmationCheckmark} />
        <SurveyConfirmationContainer>{messages.subheading}</SurveyConfirmationContainer>
        <SurveyConfirmationBody>{messages.body}</SurveyConfirmationBody>
      </ModalBody>
    </>
  )
}

export default Page3
