import { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import {
  caperAccessControl,
  shelfLabelsAccessControl,
  uposAccessControl,
} from '../access-control/connectedStoresAccess.configuration'
import Toasts from '../toasts'

const ConnectedStoresAdminCaperPage = lazy(
  () => import('../pages/caper/ConnectedStoresAdminCaperPage')
)
const ConnectedStoresAdminUposPage = lazy(() => import('../pages/upos'))
const ConnectedStoresAdminShelfLabelsPage = lazy(() => import('../pages/shelf-labels'))
// @hygen:inject../pages/shelf-labels

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const connectedStoresAdminRouter = (
  <DomainRoute route="app-admin-connected-stores">
    <div css={{ height: '100%', position: 'relative' }}>
      <Toasts />
      <DashSwitch>
        <DomainRoute
          exact
          route="app-admin-connected-stores-caper"
          component={ConnectedStoresAdminCaperPage}
          accessControl={caperAccessControl}
        />
        <DomainRoute
          exact
          route="app-admin-connected-stores-shelf-labels"
          component={ConnectedStoresAdminShelfLabelsPage}
          accessControl={shelfLabelsAccessControl}
        />
        <DomainRoute
          exact
          route="app-admin-connected-stores-upos"
          component={ConnectedStoresAdminUposPage}
          accessControl={uposAccessControl}
        />
        {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
      </DashSwitch>
    </div>
  </DomainRoute>
)
