import React, { type PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { Modal } from '@retailer-platform/shared-components'
import { Z_INDEX_FIRST } from '../../../../legacy/common/theme'
import { useNavV2FeedbackSurveyContext } from './NavV2FeedbackSurveyContext'

const ModalContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  minWidth: '560px',
})

export const NavV2FeedbackSurveyModal = ({ children }: PropsWithChildren) => {
  const { onClose, isOpen } = useNavV2FeedbackSurveyContext()

  return (
    <Modal
      maxWidth={960}
      maxHeight={720}
      onRequestClose={onClose}
      isOpen={isOpen}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      hideCloseButton={false}
      styleOverrides={{
        content: {
          padding: 0,
          width: 'auto',
        },
        overlay: {
          zIndex: Z_INDEX_FIRST + 1,
        },
      }}
    >
      <ModalContainer>{children}</ModalContainer>
    </Modal>
  )
}
