import { useCallback } from 'react'
import { useNavContext } from '../../../containers/nav/utils/NavContext'
import { useLocalForageKey } from '../../../../utils/local-storage/useLocalForageKey.hooks'

type NavV2FeedbackSurveyStorageProps = {
  hasSubmittedSurvey: boolean
  surveyPromptCount: number
  surveyLastSeenTime: number
}

export const MAX_MODAL_FREQUENCY_COUNT = 2
export const MODAL_FREQUENCY_DISPLAY_MILLISECONDS = 24 * 60 * 60 * 1000

export const useNavV2FeedbackSurveyStorage = () => {
  const { data, setData, loading } =
    useLocalForageKey<NavV2FeedbackSurveyStorageProps>('navV2FeedbackSurvey')

  return { data, setData, loading }
}

export const useNavV2FeedbackSurveyAccess = () => {
  const { hadOptedOutNavV2 } = useNavContext()
  const { data, loading } = useNavV2FeedbackSurveyStorage()

  const shouldOpenModal = useCallback(() => {
    // Don't show the modal, if the user did NOT opt out of NavV2 originally
    // Or if the current survey state data is loading
    if (!hadOptedOutNavV2 || loading) {
      return false
    }

    // Don't prompt more than 2 times
    if (data?.surveyPromptCount != null && data?.surveyPromptCount >= MAX_MODAL_FREQUENCY_COUNT) {
      return false
    }

    const surveyLastSeenTime = data?.surveyLastSeenTime
    if (surveyLastSeenTime != null && isNaN(surveyLastSeenTime)) {
      return false
    }

    // Don't prompt if we already prompted in the last 24 hours
    if (
      surveyLastSeenTime != null &&
      Date.now() - surveyLastSeenTime < MODAL_FREQUENCY_DISPLAY_MILLISECONDS
    ) {
      return false
    }

    return true
  }, [data?.surveyLastSeenTime, data?.surveyPromptCount, hadOptedOutNavV2, loading])

  return shouldOpenModal
}

export const useOnNavV2FeedbackSurveyClose = () => {
  const { data, setData } = useNavV2FeedbackSurveyStorage()

  const onClose = useCallback(() => {
    let newPromptCount = (data?.surveyPromptCount ?? 0) + 1

    // If a user submitted the survey,
    // we'll set the seen count to the max to prevent the survey showing again
    if (data?.hasSubmittedSurvey) {
      newPromptCount = MAX_MODAL_FREQUENCY_COUNT
    }
    const newLastSeenTime = new Date().getTime()

    setData({
      ...data,
      surveyPromptCount: newPromptCount,
      surveyLastSeenTime: newLastSeenTime,
    })
  }, [data, setData])

  return onClose
}
