import { useCallback, useEffect, useMemo, useState } from 'react'
import chunk from 'lodash/chunk'
import gql from 'graphql-tag'
import { type GraphQLError } from 'graphql'
import { normalizeProductCodes } from '@retailer-platform/dashboard/utils'
import { fetchDashboardClient } from '@retailer-platform/dashboard/apollo'
import { useDeepMemo } from '@retailer-platform/shared-common'
import {
  useSearchRetailerProductsQuery,
  type InstacartCustomersSharedV1Locale,
  type RetailerProductIdInput,
  type SearchRetailerProductsQuery,
  type SearchRetailerProductsQueryVariables,
  type GetRetailerProductsRequestParametersRetailerProductIdInput,
  type RetailerProduct,
} from '../api'
import {
  type RetailerProductDatum,
  SearchError,
  SearchTypes,
  type RetailerProductSearchParams,
} from '../types'

interface BulkSearchRetailerProductsProps {
  variables: Omit<SearchRetailerProductsQueryVariables, 'retailerId'> & {
    retailerIds: string[]
  }
  skip: boolean
}

type RetailerProductInput = GetRetailerProductsRequestParametersRetailerProductIdInput

const BULK_QUERY = gql`
  query searchRetailerProducts(
    $limit: BigInt
    $localeCode: InstacartCustomersSharedV1Locale
    $offset: BigInt
    $productCodes: [String]
    $query: String
    $retailerId: BigInt!
    $retailerReferenceCodes: [String]
    $retailerProductIds: [GetRetailerProductsRequestParametersRetailerProductIdInput]
    $omniSearchTerm: [String]
  ) {
    retailerToolsServiceGetRetailerProducts(
      input: {
        parameters: {
          limit: $limit
          localeCode: $localeCode
          offset: $offset
          productCodes: $productCodes
          query: $query
          retailerId: $retailerId
          retailerReferenceCodes: $retailerReferenceCodes
          retailerProductIds: $retailerProductIds
          omniSearchTerm: $omniSearchTerm
        }
      }
    ) {
      results {
        primaryImageUrl
        productCodes
        productDisplayName
        productId
        retailerReferenceCode
      }
      totalCount
    }
  }
`

// Regex to match ids in comma separated list. Matches all of the following
// 123
// 123,456,789
// 123, 456,  789
// and will ignore any alpha text
export const COMMA_SEPARATED_INT_REGEX = /(\d+)(,\s*\d+)*/g
const SPLIT_REGEX = /,|\s+/g
const BATCH_SIZE = 250

export const parseSearchTerm = (searchParams: RetailerProductSearchParams) => {
  const { searchTerm, searchType } = searchParams

  if (searchType === SearchTypes.Multi) {
    return {
      productCodes: (searchParams.productCodes ?? [])
        .filter(Boolean)
        .map(code => code.trim().toString()),
      retailerReferenceCodes: (searchParams.retailerReferenceCodes ?? [])
        .filter(Boolean)
        .map(code => code.trim().toString()),
    }
  }

  if (!searchTerm) return

  if (searchType === SearchTypes.Name) {
    return searchTerm && { query: searchTerm }
  } else if (searchType === SearchTypes.RRC) {
    const retailerReferenceCodes = searchTerm
      .split(SPLIT_REGEX)
      .filter(v => v.length)
      .map(e => e.trim())
    return retailerReferenceCodes && retailerReferenceCodes.length > 0
      ? { retailerReferenceCodes: retailerReferenceCodes }
      : undefined
  } else if (searchType === SearchTypes.UPC) {
    const upcs = searchTerm
      .split(SPLIT_REGEX)
      .filter(v => v.length)
      .reduce((upcList: string[], input: string) => {
        const upc = input.trim()
        if (upc.length > 0) upcList.push(upc)
        return upcList
      }, [])
    const normalizedUpcs = normalizeProductCodes(upcs)

    return normalizedUpcs && normalizedUpcs.length > 0
      ? { productCodes: normalizedUpcs }
      : undefined
  } else if (searchType === SearchTypes.ProductId) {
    const retailerProductIds = searchTerm
      .split(SPLIT_REGEX)
      .filter(v => v.length)
      .reduce((productIdList: RetailerProductIdInput[], input: string) => {
        const productId = input.trim()
        if (productId && productId.length > 0) {
          const retailerProductId: RetailerProductIdInput = {
            retailerId: 'retailerId' in searchParams ? searchParams.retailerId : '',
            productId: productId,
          }
          productIdList.push(retailerProductId)
        }
        return productIdList
      }, [])

    return retailerProductIds && retailerProductIds.length > 0
      ? { retailerProductIds: retailerProductIds }
      : undefined
  } else if (searchType === SearchTypes.Omni) {
    const matches = searchTerm.match(COMMA_SEPARATED_INT_REGEX)
    if (matches) {
      return {
        omniSearchTerm: matches
          .map(t => t.split(','))
          .flat()
          .map(t => t.trim()),
      }
    }
    return { query: searchTerm }
  } else {
    throw Error(`Unexpected searchType ${searchType}`)
  }
}

export const validQuery = (
  searchParams: RetailerProductSearchParams
): { isValid: boolean; searchError?: SearchError } => {
  const searchTerm = parseSearchTerm(searchParams)
  const missingQueryText = !searchTerm

  if (!missingQueryText) {
    if (searchParams.searchType === SearchTypes.ProductId) {
      for (const retailerProductId of searchTerm?.retailerProductIds || []) {
        if (retailerProductId.productId && isNaN(Number(retailerProductId.productId))) {
          return {
            isValid: false,
            searchError: SearchError.InvalidProductId,
          }
        }
      }
    }
  }

  return {
    isValid: !missingQueryText,
    searchError: undefined,
  }
}

export const useBulkSearchRetailerProducts = (props: BulkSearchRetailerProductsProps) => {
  const { skip, variables } = props
  const client = fetchDashboardClient()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<SearchRetailerProductsQuery | undefined>()
  const [error, setError] = useState<GraphQLError | undefined>()

  const fetchBatch = useCallback(
    async (variables: SearchRetailerProductsQueryVariables) => {
      const result = await client.query<
        SearchRetailerProductsQuery,
        SearchRetailerProductsQueryVariables
      >({
        query: BULK_QUERY,
        variables,
        fetchPolicy: 'no-cache',
      })

      return { ...result, variables }
    },
    [client]
  )

  const fetch = useCallback(() => {
    const { retailerIds } = variables

    const batches = retailerIds
      .map(retailerId => {
        if (variables.omniSearchTerm?.length) {
          const omniBatches = chunk(variables.omniSearchTerm, BATCH_SIZE).map(batch => ({
            ...variables,
            retailerId: retailerId,
            omniSearchTerm: batch,
          }))

          return omniBatches.map(fetchBatch)
        }

        if (variables.productCodes?.length) {
          const omniBatches = chunk(variables.productCodes, BATCH_SIZE).map(batch => ({
            ...variables,
            retailerId: retailerId,
            productCodes: batch,
          }))

          return omniBatches.map(fetchBatch)
        }

        if (variables.retailerReferenceCodes?.length) {
          const omniBatches = chunk(variables.retailerReferenceCodes, BATCH_SIZE).map(batch => ({
            ...variables,
            retailerId: retailerId,
            retailerReferenceCodes: batch,
          }))

          return omniBatches.map(fetchBatch)
        }

        if ((variables.retailerProductIds as RetailerProductInput[])?.length) {
          const omniBatches = chunk(
            variables.retailerProductIds as RetailerProductInput[],
            BATCH_SIZE
          ).map(batch => ({
            ...variables,
            retailerId: retailerId,
            retailerProductIds: batch,
          }))

          return omniBatches.map(fetchBatch)
        }

        return [fetchBatch({ ...variables, retailerId: retailerId })]
      })
      .flat()

    return Promise.all(batches)
  }, [fetchBatch, variables])

  useEffect(() => {
    const searchProducts = async () => {
      setLoading(true)

      const batches = await fetch()

      const results = batches
        .flatMap(r => {
          const batchResults = r.data?.retailerToolsServiceGetRetailerProducts?.results || []

          if (!batchResults.length) return

          return batchResults.map(result => ({
            ...result,
            retailerId: r.variables.retailerId,
          }))
        })
        .filter(Boolean) as RetailerProductDatum[]

      const groupedResults = results.reduce((acc, product) => {
        const key = product.productId!
        if (!acc[key]) {
          acc[key] = { ...product, retailerIds: [product.retailerId!] }
        } else {
          acc[key] = {
            ...product,
            retailerIds: [...(acc[key].retailerIds ?? []), product.retailerId!],
          }
        }
        return acc
      }, {} as Record<string, RetailerProductDatum>)

      const finalResults = Object.values(groupedResults)
      const errors = batches.flatMap(r => r.errors || [])
      const error = errors.length > 0 ? errors[0] : undefined

      setError(error)
      setData({
        retailerToolsServiceGetRetailerProducts: {
          results: finalResults || [],
          totalCount: finalResults.length.toString(),
        },
      })

      setLoading(false)
    }

    if (skip) {
      setData(undefined)
      setError(undefined)
      setLoading(false)
      return
    }

    searchProducts()
  }, [fetch, variables, skip])

  return { refetch: fetch, data, loading, error }
}

export const useSearchRetailerProductsV2 = (props: RetailerProductSearchParams) => {
  const variables = useDeepMemo(() => {
    const { page, locale, retailerIds, limit, retailerId } = props

    const offsetValue = limit * (page - 1)

    const result: BulkSearchRetailerProductsProps['variables'] = {
      limit: limit?.toString(),
      offset: offsetValue.toString(),
      localeCode: locale.toUpperCase() as InstacartCustomersSharedV1Locale,
      retailerIds: [...(retailerIds ?? []), retailerId],
      ...parseSearchTerm(props),
    }

    const validationCheck = validQuery(props)
    const skip = !validationCheck.isValid
    const searchError = validationCheck.searchError

    return { result, skip, searchError }
  }, [props])

  const searchError = variables.searchError

  const {
    data: retailerProductsData,
    loading,
    refetch,
    error,
  } = useBulkSearchRetailerProducts({ variables: variables.result, skip: variables.skip })

  const retailerProductsResults =
    retailerProductsData?.retailerToolsServiceGetRetailerProducts?.results

  const data: RetailerProductDatum[] | null = useMemo(() => {
    if (loading || !retailerProductsResults) {
      return null
    }

    const omniSearchTerm = variables.result.omniSearchTerm

    // sort the products based on the input
    if (omniSearchTerm) {
      const getIndex = (product: Partial<RetailerProduct>) => {
        // For empty or undefined product
        if (!product) return 0

        const { productCodes, productId } = product
        let minIndex = Infinity

        // Check for each productCode
        if (productCodes) {
          productCodes.forEach(code => {
            const index = omniSearchTerm.indexOf(code)
            if (index !== -1 && index < minIndex) minIndex = index
          })
        }

        // Check for productId
        if (productId) {
          const index = omniSearchTerm.indexOf(productId)
          if (index !== -1 && index < minIndex) minIndex = index
        }

        // If no productCode or productId found
        if (minIndex === Infinity) minIndex = 0

        return minIndex
      }
      const sortedProducts = [...retailerProductsResults].sort((a, b) => getIndex(a) - getIndex(b))

      return sortedProducts.map(({ productCodes, ...rest }) => ({
        ...rest,
        lookupCode: productCodes?.sort()[0] || null,
        inCollection: false,
        retailerCollectionAssignmentId: undefined,
      }))
    } else {
      return (
        retailerProductsResults?.map(({ productCodes, ...rest }) => ({
          ...rest,
          // Always use the first productCode after sorting alphabetically, to remain deterministic
          lookupCode: productCodes?.sort().at(0),
          // Initial state is falsey -- this may get overriden down the line by product assignments
          inCollection: false,
          retailerCollectionAssignmentId: undefined,
        })) ?? []
      )
    }
  }, [loading, retailerProductsResults, variables.result.omniSearchTerm])

  const totalPages = useMemo(() => {
    const [totalItems, itemsPerPage] = [
      retailerProductsData?.retailerToolsServiceGetRetailerProducts?.totalCount,
      variables.result.limit,
    ].map(s => (s ? parseInt(s, 10) : 0))

    if (!totalItems || !itemsPerPage) return undefined

    return Math.ceil(totalItems / itemsPerPage)
  }, [variables.result.limit, retailerProductsData])

  const potentialDuplicates = useMemo(() => {
    if (!variables.result.omniSearchTerm?.length) return false
    if (!(retailerProductsResults ?? []).length) return false

    const dupes = (variables.result.omniSearchTerm as string[])
      .map(term => {
        const match = retailerProductsResults?.find(
          res => (res.productCodes ?? []).includes(term) || res.retailerReferenceCode === term
        )
        return match
      })
      .filter(Boolean)

    return new Set(dupes).size !== dupes.length
  }, [retailerProductsResults, variables.result.omniSearchTerm])

  return {
    data,
    loading,
    error,
    totalPages,
    refetch,
    searchError,
    potentialDuplicates,
  }
}

export const useSearchRetailerProducts = (props: RetailerProductSearchParams) => {
  const variables = useDeepMemo(() => {
    const { page, locale, retailerId, limit } = props

    const offsetValue = limit * (page - 1)

    const result: SearchRetailerProductsQueryVariables = {
      retailerId: retailerId,
      limit: limit?.toString(),
      offset: offsetValue.toString(),
      localeCode: locale.toUpperCase() as InstacartCustomersSharedV1Locale,
      ...parseSearchTerm(props),
    }

    const validationCheck = validQuery(props)
    const skip = !validationCheck.isValid
    const searchError = validationCheck.searchError

    return { result, skip, searchError }
  }, [props])

  const searchError = variables.searchError

  const {
    data: retailerProductsData,
    loading,
    error,
    refetch,
  } = useSearchRetailerProductsQuery({
    variables: variables.result,
    skip: variables.skip,
  })

  const retailerProductsResults =
    retailerProductsData?.retailerToolsServiceGetRetailerProducts?.results

  const data: RetailerProductDatum[] | null = useMemo(() => {
    if (loading || !retailerProductsResults) {
      return null
    }

    return (
      retailerProductsResults?.map(({ productCodes, ...rest }) => ({
        ...rest,
        // Always use the first productCode after sorting alphabetically, to remain deterministic
        lookupCode: productCodes?.sort().at(0),
        // Initial state is falsey -- this may get overriden down the line by product assignments
        inCollection: false,
        retailerCollectionAssignmentId: undefined,
      })) ?? []
    )
  }, [loading, retailerProductsResults])

  const totalPages = useMemo(() => {
    const [totalItems, itemsPerPage] = [
      retailerProductsData?.retailerToolsServiceGetRetailerProducts?.totalCount,
      variables.result.limit,
    ].map(s => (s ? parseInt(s, 10) : 0))

    if (!totalItems || !itemsPerPage) return undefined

    return Math.ceil(totalItems / itemsPerPage)
  }, [variables.result.limit, retailerProductsData])

  return {
    data,
    loading,
    error,
    totalPages,
    refetch,
    searchError,
  }
}
