import React, { useState, useEffect } from 'react'
import { MemoryRouter } from 'react-router'
import { RetailerSatisfactionSurveyStep } from '../RetailerSatisfactionSurveyStep'
import { SurveyContextProvider } from '../RetailerSatisfactionSurveyContext'
import { useRetailerId, usePartnerId } from '../../../../utils/routing/params.hooks'
import { trackEvent } from '../../../../utils/events/trackEvent'
import { NavV2FeedbackSurveyModal } from './NavV2FeedbackSurveyModal'
import { NavV2FeedbackSurveyProvider } from './NavV2FeedbackSurveyContext'
import Page1 from './pages/Page1'
import Page2 from './pages/Page2'
import Page3 from './pages/Page3'

export enum NavV2FeedbackSurveyPages {
  Page1 = 'page1',
  Page2 = 'page2',
  Page3 = 'page3',
}

export interface NavV2FeedbackSurveyState {
  experienceComparisonResponse: string
  toolsNavigationResponse: string
  toolsNavigationOptionalResponse: string
}

export interface NavV2FeedbackSurveyControllerProps {
  isOpen: boolean
  onClose: () => void
}

export interface NavV2FeedbackSurveyProps {
  onClose: () => void
}

const defaultState = {
  experienceComparisonResponse: '',
  toolsNavigationResponse: '',
  toolsNavigationOptionalResponse: '',
}

export const NavV2FeedbackSurveyController = ({
  isOpen,
  onClose,
}: NavV2FeedbackSurveyControllerProps) => {
  const [surveyData, setSurveyData] = useState<NavV2FeedbackSurveyState>(defaultState)
  const retailerId = useRetailerId({ throwOnMissing: false }) ?? '0'
  const partnerId = usePartnerId({ throwOnMissing: false }) ?? '0'

  useEffect(() => {
    trackEvent({
      id: 'app.navV2.feedback_survey.modal.viewed',
      description: 'Tracks whenever the NavV2 feedback survey modal is viewed',
    })

    return () => {
      trackEvent({
        id: 'app.navV2.feedback_survey.modal.dismissed',
        description: 'Tracks whenever the NavV2 feedback survey modal is dismissed',
      })
    }
  }, [])

  return (
    <MemoryRouter initialEntries={[NavV2FeedbackSurveyPages.Page1]}>
      <SurveyContextProvider
        toolProps={{ toolName: 'NavV2 Launch Feedback' }}
        milestoneSlug="navV2_launch_feedback_r202410"
        surveyData={[surveyData, setSurveyData]}
        onSurveyCompleted={() => surveyData}
        retailerId={retailerId}
        partnerId={partnerId}
      >
        <NavV2FeedbackSurveyProvider isOpen={isOpen} onClose={onClose}>
          <NavV2FeedbackSurveyModal>
            <RetailerSatisfactionSurveyStep
              name={NavV2FeedbackSurveyPages.Page1}
              component={Page1}
            />
            <RetailerSatisfactionSurveyStep
              name={NavV2FeedbackSurveyPages.Page2}
              component={Page2}
            />
            <RetailerSatisfactionSurveyStep
              name={NavV2FeedbackSurveyPages.Page3}
              component={Page3}
            />
          </NavV2FeedbackSurveyModal>
        </NavV2FeedbackSurveyProvider>
      </SurveyContextProvider>
    </MemoryRouter>
  )
}
