import React, { useState, useCallback } from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { TDS_DEFAULT_COLORS, typography } from '@retailer-platform/shared-components/src/tds'
import { Button } from '@retailer-platform/shared-components'
import { trackEvent } from '../../../utils/events/trackEvent'
import { useDashMessages } from '../../../../src/intl/intl.hooks'
import { Z_INDEX_THIRD } from '../../../../src/legacy/common/theme'
import { UserFeedbackSurveyController } from '../../common/retailer-satisfaction-survey/user-feedback/UserFeedbackSurveyController'
import { useUserFeedbackContext } from './UserFeedbackContext'

const slideIn = keyframes({
  from: {
    opacity: 0,
    right: '-100px',
  },
  to: {
    opacity: 1,
    right: '-34px',
  },
})

const slideOut = keyframes({
  from: {
    opacity: 1,
    right: '-34px',
  },
  to: {
    opacity: 0,
    right: '-100px',
  },
})

const SLIDE_IN_DELAY_INITIAL_MS = 2000
const SLIDE_IN_DELAY_FINAL_MS = 0

const FeedbackButtonContainer = styled.div<{ isShown?: boolean; slideInDelay?: number }>(
  ({ isShown, slideInDelay }) => ({
    opacity: isShown ? 1 : 0,
    right: '-100px',
    position: 'fixed',
    top: '50%',
    zIndex: Z_INDEX_THIRD,
    transform: 'rotate(270deg)',
    animation: isShown
      ? `${slideIn} 750ms forwards ${slideInDelay}ms`
      : `${slideOut} 750ms forwards`,
  })
)

const FeedbackButton = styled(Button)({
  margin: 0,
  height: '32px',
  minWidth: '96px',
  borderRadius: '4px',
  ...typography.accentEmphasized,
  backgroundColor: TDS_DEFAULT_COLORS.colors.brandPrimaryRegular,
  borderColor: TDS_DEFAULT_COLORS.colors.brandPrimaryRegular,
  '&:hover': {
    borderColor: TDS_DEFAULT_COLORS.colors.brandPrimaryDark,
    backgroundColor: TDS_DEFAULT_COLORS.colors.brandPrimaryDark,
  },
  '&:active': {
    borderColor: TDS_DEFAULT_COLORS.colors.brandPrimaryDark,
    backgroundColor: TDS_DEFAULT_COLORS.colors.brandPrimaryExtraDark,
  },
})

export const UserFeedbackButton = () => {
  const { isFeedbackButtonShown } = useUserFeedbackContext()
  const [isModalShown, setIsModalShown] = useState<boolean>(false)
  const [slideInDelay, setSlideInDelay] = useState<number>(SLIDE_IN_DELAY_INITIAL_MS)
  const messages = useDashMessages({
    buttonText: 'userFeedback.button.text',
  })

  const onClickFeedbackButton = useCallback(() => {
    setIsModalShown(true)
    trackEvent({
      id: 'app.userFeedback.button.clicked',
      description: 'User clicked the IPP feedback button',
    })
    // After the user has interacted with the button, set the default
    // slide in delay to 0
    if (slideInDelay === SLIDE_IN_DELAY_INITIAL_MS) {
      setSlideInDelay(SLIDE_IN_DELAY_FINAL_MS)
    }
  }, [slideInDelay])

  if (!isFeedbackButtonShown) {
    return null
  }

  return (
    <>
      {isModalShown && <UserFeedbackSurveyController onClose={() => setIsModalShown(false)} />}
      <FeedbackButtonContainer isShown={!isModalShown} slideInDelay={slideInDelay}>
        <FeedbackButton compact data-testid="user-feedback-button" onClick={onClickFeedbackButton}>
          {messages.buttonText}
        </FeedbackButton>
      </FeedbackButtonContainer>
    </>
  )
}
