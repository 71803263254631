import React, { useMemo, useState, useEffect } from 'react'
import { ModalBody, ModalHeader } from '@retailer-platform/shared-components'
import { ButtonArrayComponent } from '../../RetailerSatisfactionSurveyComponents'
import { useDashMessages } from '../../../../../intl/intl.hooks'
import { useSurvey } from '../../RetailerSatisfactionSurveyContext'
import {
  NavV2FeedbackSurveyPages,
  type NavV2FeedbackSurveyState,
  type NavV2FeedbackSurveyProps,
} from '../NavV2FeedbackSurveyController'
import { useNavV2FeedbackSurveyStorage } from '../useNavV2FeedbackSurveyAccess'
import { trackEvent } from '../../../../../utils/events/trackEvent'

const Page1 = () => {
  const [isSubmittingSurvey, setIsSubmittingSurvey] = useState(false)
  const { surveyState, updateSurveyState, submitSurvey, goToPage } = useSurvey<
    NavV2FeedbackSurveyPages,
    NavV2FeedbackSurveyState,
    NavV2FeedbackSurveyProps
  >()
  const { data, setData } = useNavV2FeedbackSurveyStorage()

  useEffect(() => {
    if (isSubmittingSurvey) {
      submitSurvey()
      trackEvent({
        id: 'app.navV2.feedback_survey.modal.submit',
        description: 'Tracks whenever the NavV2 feedback survey modal is submitted',
        data: {
          page: 1,
        },
      })
      goToPage(NavV2FeedbackSurveyPages.Page3)
      setData({
        ...data,
        hasSubmittedSurvey: true,
      })
    }
  }, [isSubmittingSurvey, submitSurvey, goToPage, setData, data])

  const messages = useDashMessages(
    {
      title: 'navV2.userSurvey.page1.title',
      option1: 'navV2.userSurvey.page1.option1',
      option2: 'navV2.userSurvey.page1.option2',
      option3: 'navV2.userSurvey.page1.option3',
      option4: 'navV2.userSurvey.page1.option4',
      option5: 'navV2.userSurvey.page1.option5',
    },
    { br: <br /> }
  )

  const buttons = useMemo(
    () =>
      [
        { option: messages.option1, requireAdditionalFeedback: true },
        { option: messages.option2, requireAdditionalFeedback: true },
        { option: messages.option3, requireAdditionalFeedback: true },
        { option: messages.option4, requireAdditionalFeedback: true },
        { option: messages.option5, requireAdditionalFeedback: false },
      ].map(({ option, requireAdditionalFeedback }) => ({
        text: option,
        onClick: () => {
          if (isSubmittingSurvey) {
            return
          }
          updateSurveyState('experienceComparisonResponse', option)
          if (requireAdditionalFeedback) {
            goToPage(NavV2FeedbackSurveyPages.Page2)
          } else {
            setIsSubmittingSurvey(true)
          }
        },
        selected: surveyState.experienceComparisonResponse === option,
      })),
    [
      isSubmittingSurvey,
      messages?.option1,
      messages?.option2,
      messages?.option3,
      messages?.option4,
      messages?.option5,
      surveyState?.experienceComparisonResponse,
      updateSurveyState,
      goToPage,
    ]
  )

  return (
    <>
      <ModalHeader css={{ lineHeight: '26px', padding: '26px 60px 24px' }}>
        {messages.title}
      </ModalHeader>
      <ModalBody data-testid="nav-v2-feedback-page-1">
        <ButtonArrayComponent buttons={buttons} />
      </ModalBody>
    </>
  )
}

export default Page1
