const EN_US = {
  'catalogAdminDomain.title': 'Catalog Admin',
  'catalogAdminDomain.imageManagement.title': 'Fix Image Alert',
  'catalogAdminDomain.imageUploads.title': 'Image uploads',

  // Header Component
  'catalogAdminDomain.imageManagement.header.changeNotification': 'Change Email Notification',
  'catalogAdminDomain.imageManagement.header.settings': 'Settings',

  // List Component
  'catalogAdminDomain.imageManagement.list.title': 'Fix Image Alert',
  'catalogAdminDomain.imageManagement.list.columns.productImage': 'Product Image',
  'catalogAdminDomain.imageManagement.list.columns.productName': 'Product Name',
  'catalogAdminDomain.imageManagement.list.columns.upc': 'UPC Code',
  'catalogAdminDomain.imageManagement.list.columns.rrc': 'RRC Code',
  'catalogAdminDomain.imageManagement.list.columns.imageUrl': 'Image Url',
  'catalogAdminDomain.imageManagement.list.columns.imageProblem': 'Image Problem',
  'catalogAdminDomain.imageManagement.list.columns.removalDeadline': 'Removal Deadline',
  'catalogAdminDomain.imageManagement.list.actions': 'Actions',
  'catalogAdminDomain.imageManagement.list.viewImage': 'View Image',
  'catalogAdminDomain.imageManagement.list.moreActions': 'More Actions',
  'catalogAdminDomain.imageManagement.list.actions.removeImage': 'Remove/Hide Image',
  'catalogAdminDomain.imageManagement.list.actions.delayRemoval': 'Delay Removal By 14 Days',
  'catalogAdminDomain.imageManagement.list.actions.falsePositive': 'Mark As False Positive',
  'catalogAdminDomain.imageManagement.list.actions.editProduct': 'Edit Product',

  // Modal Component
  'catalogAdminDomain.imageManagement.modal.title': 'Settings',
  'catalogAdminDomain.imageManagement.modal.optOut': 'Opt Out',
  'catalogAdminDomain.imageManagement.modal.delayRemoval': 'Delay Removal',

  // Product Edit
  'catalogAdminDomain.productEdit.nav.title': 'Product edit',
  'catalogAdminDomain.productEdit.show.breadcrumb.root': 'Product Edit',
  'catalogAdminDomain.productEdit.show.search.placeholder': 'Product ID',
  'catalogAdminDomain.productEdit.show.search.button': 'Edit',
  'catalogAdminDomain.ProductEdit.show.title': 'Product',
  'catalogAdminDomain.ProductEdit.show.emptyProduct': 'Please, enter a Product ID.',
  'catalogAdminDomain.ProductEdit.show.noProductFound': 'Product not found.',

  // MD Platform
  'catalogAdminDomain.mdPlatform.nav.title': 'Measure & detect platform',
  'catalogAdminDomain.mdPlatform.create.nav.title': 'Create New Detection',
  'catalogAdminDomain.mdPlatform.create.title': 'Create a New Detection',
  'catalogAdminDomain.mdPlatform.create.label.name': 'Detection Name',
  'catalogAdminDomain.mdPlatform.create.label.description': 'Description',
  'catalogAdminDomain.mdPlatform.create.label.limit': 'Limit',
  'catalogAdminDomain.mdPlatform.create.label.defectTag': 'Fix Defect Tag',
  'catalogAdminDomain.mdPlatform.create.label.owner': 'Owner',
  'catalogAdminDomain.mdPlatform.create.label.enable': 'Enable',
  'catalogAdminDomain.mdPlatform.create.label.query': 'Query',
  'catalogAdminDomain.mdPlatform.create.button.cancel': 'Cancel',
  'catalogAdminDomain.mdPlatform.create.button.create': 'Create',

  // Quality Audit Workflows
  'catalogAdminDomain.qualityAuditWorkflows.nav.title': 'Quality audit workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.title': 'Workflow Management',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.launch': 'Launch',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.workflow': 'Workflow',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.taskCount': 'Task Count',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.source': 'Source',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.notifyUpdateSuccessMsg':
    'Update published.',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.notifyUpdateErrorMsg':
    'Update failed, please try again later.',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.dueDate': 'Due Date',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.actions': 'Actions',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.productSources': 'Product Sources',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.productMatches': 'Product Matches',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.value.view': 'View',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.inputData': 'Input Data',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.inputData.value': 'View',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.addToMyWorkflows':
    'Add To My Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.comment':
    'Set Internal/External Comments',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.flagReject': 'Reject All Values',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.flagAccept': 'Accept All Values',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.viewInputData': 'View Input Data',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.removeFromMyWorkflows':
    'Remove From My Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.subscribeToNotifications':
    'Subscribe To Notifications',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.unsubscribeToNotifications':
    'Unsubscribe To Notifications',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.outsourceWorkflows.title':
    'Outsource Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.problemTrackerWorkflows.title':
    'Problem Tracker Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.automatedDetectWorkflows.title':
    'Automated Detect Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.humanEvalAutomatedFixIngestionWorkflows.title':
    'Human Eval Automated Fix Ingestion Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.automatedFixWorkflows.title':
    'Automated Fix Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.measureAndDetect.title':
    'Measure And Detect',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.imageAttributesExtractionWorkflows.title':
    'Image Attributes Extraction',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.mlWorkflows.title': 'ML Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.myWorkflows.title': 'My Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.actions.submitAuditTasks': 'Submit Audit Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.title': 'Submit Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.progressbar.text.setup':
    'Setup Task Submission (Step 1 of 2)',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.progressbar.text.loading':
    'Loading Sample Data...',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.progressbar.text.review':
    'Review Sample Rows (Step 2 of 2)',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.createdwhy.placeholder':
    'Why are you submitting this batch?',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.createdwhy.label': 'Created why:',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.batchsize.label': 'Batch size:',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.description.label': 'Description',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.query.label': 'Batch Query',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.query.placeholder': 'Snowflake Query',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.button.continue': 'Continue',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.button.cancel': 'Cancel',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.grid.header.entity': 'Entity',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.grid.header.workflowId':
    'Workflow ID',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.button.submit': 'Submit',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.button.changeSQL': 'Change SQL',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.button.cancel': 'Cancel',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.button.submit.addAnother':
    'Submit and add more',
  'catalogAdminDomain.qualityAuditWorkflows.select.taskState.title': 'Filter Batch State:',
  'catalogAdminDomain.qualityAuditWorkflows.select.taskState.pastDue': 'Past Due',
  'catalogAdminDomain.qualityAuditWorkflows.select.taskState.pending': 'Pending',
  'catalogAdminDomain.qualityAuditWorkflows.select.taskState.completed': 'Completed',
  'catalogAdminDomain.qualityAuditWorkflows.select.taskSecondaryState.title':
    'Filter Batch Status:',
  'catalogAdminDomain.qualityAuditWorkflows.select.assignee.title': 'Filter By Assignee:',
  'catalogAdminDomain.qualityAuditWorkflows.select.reviewer.title': 'Filter By Reviewer:',
  'catalogAdminDomain.qualityAuditWorkflows.select.workflow.title': 'Workflow Type',
  'catalogAdminDomain.qualityAuditWorkflows.graph.title': 'Task Count',
  'catalogAdminDomain.qualityAuditWorkflows.graph.seriesName': 'Task Count',
  'catalogAdminDomain.qualityAuditWorkflows.graph.category.approvedTasks': 'Approved Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.graph.category.rejectedTasks': 'Rejected Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.graph.category.auditableTasks': 'Auditable Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.notifyFetchErrorMsg':
    'Momentary network issue occurred. If this persists, please contact support.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.notifyFetchSuccessMsg':
    'Network connectivity established.',
  'catalogAdminDomain.qualityAuditWorkflows.range.title': 'Filter Due Date:',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.reassign.title': 'Select Reassignment',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.reassign.select.placeholder':
    'Select Workflow',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.button.reassign': 'Reassign',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.combine.title': 'Confirm Combine',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.combine.body':
    'You are about to combine several batches, please confirm action.',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.byPass.title': 'Confirm Release',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.byPass.body':
    'You are about to release the selected batches.',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.dueDate.title': 'Override Due Date',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.reject.title': 'Confirm Rejection',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.reject.body':
    'You are about to reject the following batch.',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.sample.title': 'Override Sample Size',
  'catalogAdminDomain.qualityAuditWorkflows.batch.title': 'Batch Management',
  'catalogAdminDomain.qualityAuditWorkflows.batch.state.pending': 'Pending',
  'catalogAdminDomain.qualityAuditWorkflows.batch.state.rejected': 'Rejected',
  'catalogAdminDomain.qualityAuditWorkflows.batch.state.approved': 'Approved',
  'catalogAdminDomain.qualityAuditWorkflows.batch.state.bypassed': 'By Passed',
  'catalogAdminDomain.qualityAuditWorkflows.batch.state.force_rejected': 'Force Rejected',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.rework.title': 'Rework Batches for Audit',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.title': 'Workflow Batches for Audit',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.launch': 'Launch',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.batchNumber': 'Batch Number',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.description': 'Description',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.received': 'Received',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.recommendedSample':
    'Recommended Sample',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.sampleSize': 'Sample Size',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.numberAuditable': '# Auditable',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.dueDate': 'Due Date (UTC)',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.createdAt': 'Created At (UTC)',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.actions': 'Actions',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.status': 'Status',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.assignee': 'Assignee',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.reviewer': 'Reviewer',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.action.overrideSampleSize':
    'Override Sample Size',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.action.rejectBatch': 'Reject Batch',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.action.overrideDueDate':
    'Override Due Date',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.button.release': 'Release Without Audit',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.button.combine': 'Combine',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.button.launch': 'Launch Audit',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.legends.title': 'Legend:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.legends.valid': 'Valid Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.legends.validWithSuggestedValue':
    'Valid Value With Suggested Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.legends.validComments':
    'Valid Value With Comments',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.legends.invalid': 'Invalid Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.batchState': 'Batch State:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.batchSecondaryStatus': 'Batch Workflow Status:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.createdAt': 'Created At:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.vendor': 'Vendor:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.reviewer': 'Reviewer:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.assignee': 'Assignee:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.completedAt': 'Completed At:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.title': 'Tasks for Auditing',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.button.batchComments': 'Batch Comments',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.button.submit': 'Submit Completed Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.dueDate': 'Due Date',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.setFlag': 'Set Flag:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.filterState': 'Filter State:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.acceptTask': 'Accept Task',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.rejectTask': 'Reject Task',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.resetTask': 'Reset Task',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.state': 'State',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.entityId': 'Entity Id',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.productId': 'Product Id',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.retailerProductId':
    'Retailer Product Id',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.upc': 'UPC Code',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.image': 'Image',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.freeze': 'Freeze Column',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.unfreeze': 'Unfreeze Column',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.actions.leaveComments':
    'Leave Internal/External Comment',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.flagValue': 'Flag Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.flagValueWithSuggestValue':
    'Flag Value With Suggested Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.comment': 'Comment',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.title.comments': 'Comments',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.title.input': 'Input Data',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.value': 'Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.flag': 'Flag',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.submit': 'Submit',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.close': 'Close',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.cancel': 'Cancel',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.valid': 'Valid',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.faq': 'FAQ',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.invalid': 'Invalid',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.existingInternalComment':
    'Existing Internal Comment',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.existingExternalComment':
    'Existing External Comment',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.internalComment': 'Internal Comment',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.externalComment': 'External Comment',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.optional': 'Optional',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.suggestedValue': 'Suggested Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.notFound': 'No input data found',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.errorLoading':
    'Error while loading input data',

  'catalogAdminDomain.qualityAuditWorkflows.batch.errorMessage':
    'Unable to update batch, due to an issue, please try again later.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.flagAll': 'Flag All',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.clearFlags': 'Clear Flags',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.errorMessage':
    'Unable to save, because a conflict has occurred. Please, review and try again.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.conflictMessage':
    'There was an update to the existing comments, please review it before saving.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.tab.currentData': 'New Data',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.tab.conflictingData': 'Conflicting Data',
  'catalogAdminDomain.imageUploads.productNotFound': 'Item not found',
  'catalogAdminDomain.imageUploads.invalidImage': 'Failed to save image',
  'catalogAdminDomain.mdPlatformList.table.filters.id': 'Filter By Id',
  'catalogAdminDomain.mdPlatformList.table.filters.name': 'Filter By Name',
  'catalogAdminDomain.mdPlatformList.table.filters.owner': 'Filter By Owner',
  'catalogAdminDomain.mdPlatformList.motd.button': 'Learn More',
  'catalogAdminDomain.mdPlatformList.motd':
    "Welcome to the Catalog's measure and detect platform! Here, you can create detection queries to find problematic issues in Catalog. You can learn more by clicking on the button below.",
  'catalogAdminDomain.mdPlatformList.createButton': 'Create New Detection',
  'catalogAdminDomain.mdPlatformList.notify.errorMessage':
    'Momentary network issue occurred. If this persists, please contact support.',
  'catalogAdminDomain.mdPlatformList.table.reviewStates.inReview': 'IN REVIEW',
  'catalogAdminDomain.mdPlatformList.table.reviewStates.testing': 'TESTING',
  'catalogAdminDomain.mdPlatformList.table.reviewStates.rejected': 'REJECTED',
  'catalogAdminDomain.mdPlatformList.table.reviewStates.approved': 'APPROVED',
  'catalogAdminDomain.mdPlatformList.table.details.label': 'Expand for more details',
  'catalogAdminDomain.mdPlatformList.table.query': 'Query:',
  'catalogAdminDomain.mdPlatformList.table.createdBy': 'Created By:',
  'catalogAdminDomain.mdPlatformList.table.createdWhy': 'Created Why:',
  'catalogAdminDomain.mdPlatformList.table.values.none': 'None',
  'catalogAdminDomain.mdPlatformList.table.headers.id': 'Id',
  'catalogAdminDomain.mdPlatformList.table.headers.name': 'Name',
  'catalogAdminDomain.mdPlatformList.table.headers.version': 'Version',
  'catalogAdminDomain.mdPlatformList.table.headers.enabled': 'Enabled',
  'catalogAdminDomain.mdPlatformList.table.headers.enabled.help':
    'Displays if this detection is currently running or not.',
  'catalogAdminDomain.mdPlatformList.table.headers.limit': 'Limit',
  'catalogAdminDomain.mdPlatformList.table.headers.limit.help':
    'The max number of requests we can send',
  'catalogAdminDomain.mdPlatformList.table.headers.owner': 'Owner',
  'catalogAdminDomain.mdPlatformList.table.headers.status': 'Review Status',
  'catalogAdminDomain.mdPlatformList.table.headers.status.help':
    'Displays the state this version is in.',
  'catalogAdminDomain.mdPlatformList.table.headers.actions': 'Actions',
  'catalogAdminDomain.mdPlatformList.title': 'Measure And Detect Platform',
  'catalogAdminDomain.mdPlatformList.table.actions.edit': 'Edit Detection',
  'catalogAdminDomain.mdPlatformList.table.actions.details': 'View Details',
  'catalogAdminDomain.mdPlatformList.modal.cancel': 'Cancel',
  'catalogAdminDomain.mdPlatformList.modal.title': 'Modify',
  'catalogAdminDomain.mdPlatformList.modal.submit': 'Submit',
  'catalogAdminDomain.mdPlatformList.modal.reset': 'Reset',
  'catalogAdminDomain.mdPlatformList.modal.input.reason': 'Reason:',
  'catalogAdminDomain.mdPlatformList.modal.input.reason.placeholder':
    'Enter reason for the change:',
  'catalogAdminDomain.mdPlatformList.modal.input.limit': 'Limit:',
  'catalogAdminDomain.mdPlatformList.modal.input.enabled': 'Enabled:',
  'catalogAdminDomain.mdPlatformList.modal.input.defectTag': 'Defect Tag:',
  'catalogAdminDomain.mdPlatformList.modal.input.cadence': 'Cadence:',
  'catalogAdminDomain.mdPlatformList.modal.input.owner': 'Owner:',
  'catalogAdminDomain.mdPlatformList.modal.input.description': 'Description:',
  'catalogAdminDomain.mdPlatformList.modal.error.create': 'Unable to get detection information',
  'catalogAdminDomain.mdPlatformList.modal.error.get': 'Unable to create new detection version',
  'catalogAdminDomain.mdPlatformList.modal.input.query': 'Query:',
  'catalogAdminDomain.mdPlatformDetails.title': 'Details',
  'catalogAdminDomain.mdPlatformDetails.notify.errorMessage':
    'Momentary network issue occurred. If this persists, please contact support.',
  'catalogAdminDomain.mdPlatformDetails.table.version': 'Version',
  'catalogAdminDomain.mdPlatformDetails.table.status': 'Status',
  'catalogAdminDomain.mdPlatformDetails.table.comments': 'Comments',
  'catalogAdminDomain.mdPlatformDetails.table.createdBy': 'Created By',
  'catalogAdminDomain.mdPlatformDetails.table.createdReason': 'Created Reason',
  'catalogAdminDomain.mdPlatformDetails.table.changes': 'Changes',
  'catalogAdminDomain.mdPlatformDetails.table.actions': 'Actions',
  'catalogAdminDomain.mdPlatformDetails.tab.properties': 'Properties',
  'catalogAdminDomain.mdPlatformDetails.tab.changes': 'Changes',
  'catalogAdminDomain.mdPlatform.versions.state': 'State:',
  'catalogAdminDomain.mdPlatform.versions.enabled': 'Enabled:',
  'catalogAdminDomain.mdPlatformDetails.approve': 'Approve',
  'catalogAdminDomain.mdPlatformDetails.reject': 'Reject',
  'catalogAdminDomain.mdPlatformDetails.tombstoned': 'Tombstoned',
  'catalogAdminDomain.mdPlatformDetails.tombstone': 'Tombstone',
  'catalogAdminDomain.mdPlatformDetails.tombstoneWarning':
    'You will permanently undo the changes made from this version.',
  'catalogAdminDomain.mdPlatformDetails.previous': 'Previous',
  'catalogAdminDomain.mdPlatformDetails.next': 'Next',
  'catalogAdminDomain.mdPlatformDetails.modal.title': 'Confirm',
  'catalogAdminDomain.mdPlatformDetails.modal.cancel': 'Cancel',
  'catalogAdminDomain.mdPlatformDetails.modal.submit': 'Submit',
  'catalogAdminDomain.mdPlatformDetails.modal.reason': 'State Reason',
} as const

export default EN_US
