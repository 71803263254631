import React, {
  type PropsWithChildren,
  useCallback,
  createContext,
  useContext,
  useMemo,
} from 'react'
import { useSurvey } from '../RetailerSatisfactionSurveyContext'
import {
  type NavV2FeedbackSurveyPages,
  type NavV2FeedbackSurveyState,
  type NavV2FeedbackSurveyProps,
} from './NavV2FeedbackSurveyController'

interface NavV2FeedbackContextType {
  isOpen?: boolean
  onClose?: () => void
}

interface NavV2FeedbackProviderProps extends PropsWithChildren {
  isOpen: boolean
  onClose: () => void
}

const NavV2FeedbackSurveyContext = createContext<NavV2FeedbackContextType>({})

export const NavV2FeedbackSurveyProvider = ({
  children,
  onClose,
  isOpen,
}: NavV2FeedbackProviderProps) => {
  const { surveyState, submitSurvey, isSurveySubmitted, loading } = useSurvey<
    NavV2FeedbackSurveyPages,
    NavV2FeedbackSurveyState,
    NavV2FeedbackSurveyProps
  >()

  const onCloseCallback = useCallback(() => {
    const comparisonResponse = surveyState?.experienceComparisonResponse
    const toolsResponse = surveyState?.toolsNavigationResponse

    if (
      !loading &&
      !isSurveySubmitted &&
      ((comparisonResponse && comparisonResponse.trim() !== '') ||
        (toolsResponse && toolsResponse?.trim() !== ''))
    ) {
      submitSurvey?.()
    }
    onClose()
  }, [
    surveyState?.experienceComparisonResponse,
    surveyState?.toolsNavigationResponse,
    loading,
    isSurveySubmitted,
    onClose,
    submitSurvey,
  ])

  const navV2ProviderValue = useMemo(
    () => ({ onClose: onCloseCallback, isOpen }),
    [onCloseCallback, isOpen]
  )

  return (
    <NavV2FeedbackSurveyContext.Provider value={navV2ProviderValue}>
      {children}
    </NavV2FeedbackSurveyContext.Provider>
  )
}

export const useNavV2FeedbackSurveyContext = () => useContext(NavV2FeedbackSurveyContext)
