import { transformFilterParamsToApiFilterSelectionsFactory } from '../utils/insightsPortalSchemas.utils'
import { GroupBys } from '../agg-warehouse-location-campaign-performance'
import { aggCampaignSchemaFilterParamsConfig } from '../agg-warehouse-location-campaign-performance/insightsPortalAggCampaignSchema.hooks'
import {
  createSchemaConfig,
  buildTimeIntervalGroupBysConfig,
  buildTimeIntervalDimensionsConfig,
  buildTimeIntervalDimensionsGrouping,
  PrivateSchemaPropertyType,
} from '../helpers/schema.helpers'
import { insightsPortalERVMetricAccessControl } from '../../access-control/insightsPortalERVMetricAccess.configuration'
import { InsightsFormatterType, InsightsGroupByTypes } from '../types/schema.types'
import { insightsPortalInstacartSiteConfigurationAccessControl } from '../../access-control/insightsPortalInstacartSiteConfigurationAccess.configuration'
import { Metrics, Dimensions } from './fact-campaign-performance-stitch-order-delivery.types'

export { Metrics, Dimensions }

export const schemaConfig = createSchemaConfig({
  metrics: Metrics,
  dimensions: Dimensions,
  defaultFilters: [
    {
      name: Dimensions.IS_INSTACART_STORE_CONFIGURATION,
      values: ['No'],
      accessControlEnablement: insightsPortalInstacartSiteConfigurationAccessControl,
    },
  ],
  metricsConfig: {
    [Metrics.AVG_BASKET_SPEND_LIFT]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.AVG_BASKET_SPEND_LIFT',
      formatter: InsightsFormatterType.Currency,
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.AVG_BASKET_SPEND_LIFT',
    },
    [Metrics.AVG_BASKET_SPEND_WITH_PROMO]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.AVG_BASKET_SPEND_WITH_PROMO',
      formatter: InsightsFormatterType.Currency,
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.AVG_BASKET_SPEND_WITH_PROMO',
    },
    [Metrics.AVG_COST_PER_ACTIVATION]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.AVG_COST_PER_ACTIVATION',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.AVG_COST_PER_ORDER]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.AVG_COST_PER_ORDER',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.AVG_COST_PER_RESURRECTION]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.AVG_COST_PER_RESURRECTION',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.AVG_COST_PER_USER_ACTIVATED]: {
      label: 'insightsPortalDomain.campaigns.metrics.AVG_COST_PER_USER_ACTIVATED',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.AVG_COST_PER_USER_REACTIVATED]: {
      label: 'insightsPortalDomain.campaigns.metrics.AVG_COST_PER_USER_REACTIVATED',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.AVG_COST_PER_USER_RETAINED]: {
      label: 'insightsPortalDomain.campaigns.metrics.AVG_COST_PER_USER_RETAINED',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.AVG_COST_PER_USER]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.AVG_COST_PER_USER',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.AVG_DAILY_CAMPAIGN_SPEND]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.AVG_DAILY_CAMPAIGN_SPEND',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.AVG_FIRST_ORDER_PER_STORE]: {
      label:
        'insightsPortalDomain.performanceDashboard.geographyPerformance.table.header.avgNewUsersPerStore',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.AVG_GMV_PER_STORE]: {
      label: 'insightsPortalDomain.performanceDashboard.metrics.AVG_GMV',
      formatter: InsightsFormatterType.Currency,
      private: true,
    },
    [Metrics.AVG_GMV]: {
      label: 'insightsPortalDomain.performanceDashboard.metrics.AVG_GMV',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.AVG_RATING]: {
      label: 'insightsPortalDomain.performanceDashboard.metrics.AVG_RATING',
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.AVG_UNIT_SPEND_LIFT]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.AVG_UNIT_SPEND_LIFT',
      formatter: InsightsFormatterType.Decimal,
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.AVG_UNIT_SPEND_LIFT',
    },
    [Metrics.AVG_UNIT_SPEND_WITH_PROMO]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.AVG_UNIT_SPEND_WITH_PROMO',
      formatter: InsightsFormatterType.Integer,
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.AVG_UNIT_SPEND_WITH_PROMO',
    },
    [Metrics.AVG_USER_PER_STORE]: {
      label:
        'insightsPortalDomain.performanceDashboard.geographyPerformance.table.header.avgUserPerStore',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.COUNT_FIRST_ORDER]: {
      label: 'insightsPortalDomain.performanceDashboard.metrics.COUNT_FIRST_ORDER',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.COUNT_ORDER]: {
      label: 'insightsPortalDomain.performanceDashboard.metrics.COUNT_ORDER',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.COUNT_USERS_ACTIVATED]: {
      label: 'insightsPortalDomain.campaigns.metrics.COUNT_USERS_ACTIVATED',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.COUNT_USERS_REACTIVATED]: {
      label: 'insightsPortalDomain.campaigns.metrics.COUNT_USERS_REACTIVATED',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.COUNT_USERS_RETAINED]: {
      label: 'insightsPortalDomain.campaigns.metrics.COUNT_USERS_RETAINED',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.COUNT_USERS]: {
      label: 'insightsPortalDomain.performanceDashboard.metrics.COUNT_USER',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.NUM_ACTIVE_CAMPAIGNS]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.NUM_ACTIVE_CAMPAIGNS',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.NUM_DISTINCT_CAMPAIGNS]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.NUM_DISTINCT_CAMPAIGNS',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.NUM_ENDING_SOON_CAMPAIGNS]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.NUM_ENDING_SOON_CAMPAIGNS',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.NUM_ORDERS_OFFERS]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.NUM_ORDERS_OFFERS',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.RATIO_AVG_UNIT_SPEND_LIFT]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.RATIO_AVG_UNIT_SPEND_LIFT',
      formatter: InsightsFormatterType.Percentage,
      private: true,
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.RATIO_AVG_UNIT_SPEND_LIFT',
    },
    [Metrics.RATIO_BASKET_SPEND_LIFT]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.RATIO_BASKET_SPEND_LIFT',
      formatter: InsightsFormatterType.Percentage,
      tooltip:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.tooltips.RATIO_BASKET_SPEND_LIFT',
    },
    [Metrics.REDEMPTION_RATE]: {
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.REDEMPTION_RATE',
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.REORDER_RATE_28_DAY]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.REORDER_RATE_28_DAY',
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.RETURN_ON_AD_SPEND]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.RETURN_ON_AD_SPEND',
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.SUM_ACTIVATIONS]: {
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.SUM_ACTIVATIONS',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.SUM_APPLICATIONS]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.SUM_APPLICATIONS',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.SUM_ATTRIBUTED_SALES]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.SUM_ATTRIBUTED_SALES',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.SUM_CAMPAIGN_SPEND_OUTSTANDING]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.SUM_CAMPAIGN_SPEND_OUTSTANDING',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.SUM_CAMPAIGN_SPEND]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.SUM_CAMPAIGN_SPEND',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.SUM_GMV]: {
      label: 'insightsPortalDomain.performanceDashboard.metrics.SUM_GMV',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.SUM_ORDERS_OUTSTANDING]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.SUM_ORDERS_OUTSTANDING',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.SUM_REDEMPTIONS]: {
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.SUM_REDEMPTIONS',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.SUM_RESURRECTIONS]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.SUM_RESURRECTIONS',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.SUM_USERS_OFFERS]: {
      label: 'insightsPortalDomain.campaigns.metrics.SUM_USERS_OFFERS',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.SUM_USERS]: {
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.SUM_USERS',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.LAST_ETL_UPDATE]: {
      private: true,
      formatter: InsightsFormatterType.Date,
    },
    [Metrics.OVERALL_AVG_ORDERED_QTY]: {
      private: true,
    },
    [Metrics.SUM_ATTRIBUTED_ERV]: {
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.SUM_ATTRIBUTED_ERV',
      formatter: InsightsFormatterType.Currency,
      private: insightsPortalERVMetricAccessControl,
    },
  },
  dimensionsConfig: {
    [Dimensions.CAMPAIGN]: {
      label: 'insightsPortalDomain.campaigns.groupBys.CAMPAIGN_NAME',
      private: PrivateSchemaPropertyType.DataExplorerFilters,
    },
    [Dimensions.CAMPAIGN_ID]: {
      type: InsightsGroupByTypes.Info,
      private: PrivateSchemaPropertyType.DataExplorerFilters,
      label: 'insightsPortalDomain.campaigns.groupBys.CAMPAIGN_ID',
    },
    [Dimensions.CAMPAIGN_TYPE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.campaignType.label',
    },
    [Dimensions.CAMPAIGN_ENDING_SOON]: {
      label: 'insightsPortalDomain.filters.campaignEndingSoon.label',
    },
    [Dimensions.DELIVERY_STATE]: {
      private: true,
      label: 'insightsPortalDomain.filters.deliveryState.label',
    },
    [Dimensions.DIM_CAMPAIGN_END_DATE]: {
      date: true,
      formatter: InsightsFormatterType.Date,
      type: InsightsGroupByTypes.TimeInterval,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.CAMPAIGN_END_DATE',
    },
    [Dimensions.DIM_CAMPAIGN_NAME]: {
      private: true,
      label: 'insightsPortalDomain.adsCampaignDashboard.table.header.campaignName',
    },
    [Dimensions.DIM_CAMPAIGN_OBJECTIVE]: {
      label: 'insightsPortalDomain.campaigns.groupBys.CAMPAIGN_OBJECTIVE',
    },
    [Dimensions.DIM_CAMPAIGN_START_DATE]: {
      date: true,
      formatter: InsightsFormatterType.Date,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.CAMPAIGN_START_DATE',
    },
    [Dimensions.DIM_CAMPAIGN_STATUS]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.adsCampaignDashboard.table.header.campaignStatus',
    },
    [Dimensions.IS_INSTACART_STORE_CONFIGURATION]: {
      label: 'insightsPortalDomain.campaigns.groupBys.IS_INSTACART_STORE_CONFIGURATION',
      private: true,
    },
    [Dimensions.DIM_STORE_CONFIGURATION]: {
      label: 'insightsPortalDomain.campaigns.groupBys.DIM_STORE_CONFIGURATION_ID',
    },
    [Dimensions.DIM_DISCOVERABILITY]: {
      type: InsightsGroupByTypes.Info,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.dimensions.DIM_DISCOVERABILITY',
    },
    [Dimensions.DIM_USER_SEGMENT]: {
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.USER_SEGMENT',
      private: true,
    },
    [Dimensions.DIM_USER_SEGMENT_CAMPAIGN_TYPE]: {
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.USER_SEGMENT',
    },
    [Dimensions.ORDER_SOURCE]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.ORDER_SOURCE',
    },
    [Dimensions.INSIGHTS_PORTAL_ORDER_SOURCE]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.ORDER_SOURCE',
    },
    [Dimensions.ORDER_SOURCE_WITH_DELIVERY]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.ORDER_SOURCE',
    },
    [Dimensions.TAAS_USER_SEGMENT]: {
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.USER_SEGMENT',
      private: true,
    },
    [Dimensions.TAAS_USER_SEGMENT_CAMPAIGN_TYPE]: {
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.USER_SEGMENT',
      private: true,
    },
    [Dimensions.WAREHOUSE]: {
      label: 'insightsPortalDomain.creatives.filters.retailer',
    },
    [Dimensions.WAREHOUSE_LOCATION]: {
      label: 'insightsPortalDomain.campaigns.groupBys.WAREHOUSE_LOCATION',
    },
    [Dimensions.WAREHOUSE_LOCATION_CODE]: {
      label: 'insightsPortalDomain.campaigns.groupBys.WAREHOUSE_LOCATION_CODE',
    },
    [Dimensions.WAREHOUSE_LOCATION_REGION]: {
      label: 'insightsPortalDomain.campaigns.groupBys.WAREHOUSE_LOCATION_REGION',
    },
    [Dimensions.WAREHOUSE_LOCATION_STATE]: {
      label: 'insightsPortalDomain.campaigns.groupBys.WAREHOUSE_LOCATION_STATE',
    },
    [Dimensions.ZONE_STATE]: {
      label: 'insightsPortalDomain.campaigns.groupBys.ZONE_STATE',
      private: true,
    },
    ...buildTimeIntervalDimensionsConfig,
  },
  dimensionGroupings: [
    buildTimeIntervalDimensionsGrouping,
    {
      label: 'insightsPortalDomain.filters.stores',
      dimensions: [
        Dimensions.WAREHOUSE_LOCATION_CODE,
        Dimensions.WAREHOUSE_LOCATION_REGION,
        Dimensions.WAREHOUSE_LOCATION_STATE,
        Dimensions.WAREHOUSE,
        Dimensions.DIM_STORE_CONFIGURATION,
        Dimensions.WAREHOUSE_LOCATION,
      ],
    },
    {
      label: 'insightsPortalDomain.filters.orderSources.label',
      dimensions: [Dimensions.INSIGHTS_PORTAL_ORDER_SOURCE],
    },
    {
      label: 'insightsPortalDomain.campaigns.groupBys.CAMPAIGN_NAME',
      dimensions: [
        Dimensions.DIM_CAMPAIGN_OBJECTIVE,
        Dimensions.DIM_DISCOVERABILITY,
        Dimensions.DIM_CAMPAIGN_STATUS,
        Dimensions.CAMPAIGN,
        Dimensions.CAMPAIGN_ENDING_SOON,
        Dimensions.DIM_CAMPAIGN_END_DATE,
        Dimensions.DIM_CAMPAIGN_START_DATE,
        Dimensions.CAMPAIGN_ID,
        Dimensions.DIM_USER_SEGMENT_CAMPAIGN_TYPE,
        Dimensions.CAMPAIGN_TYPE,
      ],
    },
  ],
  // TODO: deprecate
  filterConfigs: aggCampaignSchemaFilterParamsConfig,
  transformFilterParamsToApiFilterSelections: transformFilterParamsToApiFilterSelectionsFactory(
    aggCampaignSchemaFilterParamsConfig
  ),
  groupBys: GroupBys,
  groupBysConfig: {
    [GroupBys.DIM_CAMPAIGN_END_DATE]: {
      formatter: InsightsFormatterType.Date,
      type: InsightsGroupByTypes.TimeInterval,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.CAMPAIGN_END_DATE',
    },
    [GroupBys.CAMPAIGN_ID]: {
      type: InsightsGroupByTypes.Info,
      private: true,
    },
    [GroupBys.DIM_CAMPAIGN_NAME]: {
      label: 'insightsPortalDomain.adsCampaignDashboard.table.header.campaignName',
    },
    [GroupBys.DIM_CAMPAIGN_START_DATE]: {
      formatter: InsightsFormatterType.Date,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.CAMPAIGN_START_DATE',
    },
    [GroupBys.DIM_CAMPAIGN_STATUS]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.adsCampaignDashboard.table.header.campaignStatus',
    },
    [GroupBys.CAMPAIGN_TYPE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.campaignType.label',
    },
    [GroupBys.ORDER_SOURCE]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.ORDER_SOURCE',
    },
    [GroupBys.REGION]: {
      type: InsightsGroupByTypes.Program,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.WAREHOUSE_LOCATION_REGION',
    },
    ...buildTimeIntervalGroupBysConfig,
    [GroupBys.TAAS_USER_SEGMENT_CAMPAIGN_TYPE]: {
      type: InsightsGroupByTypes.Info,
      private: true,
    },
    [GroupBys.DIM_CAMPAIGN_OBJECTIVE]: {
      label: 'insightsPortalDomain.campaigns.groupBys.CAMPAIGN_OBJECTIVE',
    },
    [GroupBys.RFM_DATE_PT]: {
      type: InsightsGroupByTypes.TimeInterval,
      private: true,
    },
    [GroupBys.USER_SEGMENT]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.USER_SEGMENT',
    },
    [GroupBys.DIM_USER_SEGMENT_CAMPAIGN_TYPE]: {
      label: 'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.groupBys.USER_SEGMENT',
    },
    [GroupBys.WAREHOUSE_LOCATION]: {
      private: true,
    },
    [GroupBys.WAREHOUSE_LOCATION_STATE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.campaigns.groupBys.WAREHOUSE_LOCATION_STATE',
      private: true,
    },
    [GroupBys.ZONE_STATE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.campaigns.groupBys.ZONE_STATE',
      private: true,
    },
    [GroupBys.WAREHOUSE_LOCATION_LATITUDE]: {
      private: true,
    },
    [GroupBys.WAREHOUSE_LOCATION_LONGITUDE]: {
      private: true,
    },
    [GroupBys.DELIVERY_STATE]: {
      type: InsightsGroupByTypes.Info,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.deliveryState.label',
    },
  },
})
