import { useState, useCallback, useMemo, useRef } from 'react'
import { matchPath } from 'react-router'
import { FeatureToggle } from '../../contexts/featureToggles/FeatureToggleContext'
import useAccessControl from '../AccessControl/useAccessControl'
import FeatureAnnouncementImage from '../../../assets/featureHighlights/feature-announcement.png'
import FeatureHighlightExample from './FeatureHighlightExample'
import { NavV2FeedbackSurveyController } from '../../../gin-and-tonic/common/retailer-satisfaction-survey/nav-v2-feedback/NavV2FeedbackSurveyController'
import { useNavV2FeedbackSurveyAccess } from '../../../gin-and-tonic/common/retailer-satisfaction-survey/nav-v2-feedback/useNavV2FeedbackSurveyAccess'
import { useOnNavV2FeedbackSurveyClose } from '../../../gin-and-tonic/common/retailer-satisfaction-survey/nav-v2-feedback/useNavV2FeedbackSurveyAccess'
import { type FeatureHighlight } from './types'
import { trackEvent } from '../../../utils/events/trackEvent'
import { URL_DASHBOARD } from '../../../utils/routing/routes'

export interface FeatureEducationContext {
  isOpen: boolean
  onOpen: () => void
  onClose: (id?: unknown) => void
  onClick: () => void
  feature: FeatureHighlight
}

const noop = () => {}

export const defaultValues: FeatureEducationContext = {
  isOpen: false,
  onOpen: noop,
  onClose: noop,
  onClick: noop,
  feature: null,
}

// Number of times we'll show each modal to users
// (Note that a modal is only marked as "seen" after the user clicks either the CTA or "Maybe Later" button)
const DEFAULT_MAX_SEEN_COUNT = 2

const getFeatures = () => {
  // List of features that will be potentially shown to users.
  // Note that only one modal will be shown at a time. Once a users dismisses a modal MAX_SEEN_COUNT times, we will show the next modal.
  // (Modals will be shown in the order they are added here, subject to access control/seen checks)
  const CURRENT_FEATURES: FeatureHighlight[] = [
    // Note that this modal is no longer in use, but left here as an example:
    // {
    //   id: 'mfa_launch_april_may_2024',
    //   img: FeatureAnnouncementImage,
    //   Component: FeatureHighlightExample,
    //   buttonTextMsg: 'featureEducation.modal.feature.mfaLaunch.buttonText',
    //   buttonTarget: goToAccountSecurity,  // Note that this was defined as `const goToAccountSecurity = useGoToAccountSecurityPage()`
    //   // TODO: fix typing here so we can use domain access control
    //   accessControl: {
    //     permissions: [Permission.ManageWarehouseSecurity],
    //     featureToggles: [FeatureToggle.MfaModalDisplay],
    //   },
    //   featureRoutes: [URL_DASHBOARD],
    // },
    {
      id: 'navV2_user_survey_oct_2024',
      Component: NavV2FeedbackSurveyController,
      accessControl: {
        permissions: [],
        featureToggles: [],
      },
      renderAsStandaloneComponent: true,
      featureRoutes: [],
      featureMaxSeenCount: 2,
      useAdditionalAccess: useNavV2FeedbackSurveyAccess,
      useOnCloseCallback: useOnNavV2FeedbackSurveyClose,
    },
  ]
  return CURRENT_FEATURES
}

const getFeatureRouteMatch = (featureRoutes: string[] | undefined, pathname: string) => {
  if (!featureRoutes?.length) {
    return true
  }

  return featureRoutes.some(route =>
    matchPath(pathname, {
      path: route,
      exact: true,
      strict: false,
    })
  )
}

const getFeatureAdditionalAccess = (features: FeatureHighlight[]) =>
  features.reduce((acc, obj) => {
    const accessCallback = obj.useAdditionalAccess?.() ?? null
    return { ...acc, [obj.id]: accessCallback }
  }, {})

const getFeatureOnCloseCallbacks = (features: FeatureHighlight[]) =>
  features.reduce((acc, obj) => {
    const onCloseCallback = obj.useOnCloseCallback?.() ?? null
    return { ...acc, [obj.id]: onCloseCallback }
  }, {})

const getNextFeature = (currentFeatures: FeatureHighlight[], pathname: string) => {
  const hasAccess = useAccessControl()

  // Check if any feature has custom eligibility checks
  const featureAccessCallbacks = getFeatureAdditionalAccess(currentFeatures)

  // find index of first feature that passes access control check (and has not yet been seen MAX_SEEN_COUNT times)
  const idx = currentFeatures.findIndex(
    ({ id, accessControl, featureRoutes, featureMaxSeenCount }) => {
      if (featureAccessCallbacks[id] && !featureAccessCallbacks[id]()) {
        return false
      }

      if (!accessControl) {
        return false
      }
      // check to see if feature has been seen maxSeenThreshold times
      const seenCount = localStorage.getItem(`FeatureEducation:${id}.seenCount`)
      const maxSeenCount = featureMaxSeenCount ?? DEFAULT_MAX_SEEN_COUNT
      const featureRouteMatch = getFeatureRouteMatch(featureRoutes, pathname)

      if (seenCount && parseInt(seenCount) >= maxSeenCount) {
        return false
      }

      // If any specific routes are provided, only show feature if theres one match
      if (!featureRouteMatch) {
        return false
      }

      if (hasAccess(accessControl)) {
        return true
      }

      return false
    }
  )

  if (idx === -1) {
    return null
  } else {
    return currentFeatures[idx]
  }
}

const useFeatureEducation = (pathname: string) => {
  const hasAccess = useAccessControl()

  // Roulette for overall feature education feature; allows us to disable feature modals completely if there are any issues.
  const isFeatureEnabled = hasAccess({
    warehouseFeatureToggles: [FeatureToggle.FeatureEducation],
  })

  const features = getFeatures()
  const feature = getNextFeature(features, pathname)

  const featureSeenRef = useRef({})
  const featureOnCloseCallbacks = getFeatureOnCloseCallbacks(features)
  const featureOnCloseCallback = featureOnCloseCallbacks[feature?.id]

  /*
    If the user has seen none of the new features, assume the user has not seen the modal and display it.
    Otherwise, we assume the user has dismissed the modal and we should not display the modal
    */
  const [isOpen, setIsOpen] = useState(feature ? true : false)

  const shouldOpenModal =
    !isOpen &&
    feature &&
    isFeatureEnabled &&
    !featureSeenRef.current[feature.id] &&
    getFeatureRouteMatch(feature.featureRoutes, pathname)

  const incrementSeenFeatureCount = useCallback(id => {
    const seenCount = localStorage.getItem(`FeatureEducation:${id}.seenCount`)
    const newSeenCount = seenCount ? parseInt(seenCount) + 1 : 1
    localStorage.setItem(`FeatureEducation:${id}.seenCount`, newSeenCount.toString())
  }, [])

  const onClose = useCallback(() => {
    setIsOpen(false)
    if (feature) {
      incrementSeenFeatureCount(feature.id)
      trackEvent({
        id: `feature_education_modal.dismissed`,
        description: `Dismissed feature modal`,
        data: {
          modalId: feature.id,
        },
      })
    }
    featureOnCloseCallback?.()
  }, [incrementSeenFeatureCount, featureOnCloseCallback, feature])

  const onOpen = useCallback(() => {
    setIsOpen(true)

    if (feature) {
      featureSeenRef.current[feature.id] = true
      trackEvent({
        id: `feature_education_modal.viewed`,
        description: `Viewed feature education modal`,
        data: {
          modalId: feature.id,
        },
      })
    }
  }, [feature])

  const onClick = useCallback(() => {
    if (feature) {
      incrementSeenFeatureCount(feature.id)
      feature?.buttonTarget?.()

      trackEvent({
        id: `feature_education_modal.cta_clicked`,
        description: `Clicked main CTA button on feature education modal`,
        data: {
          modalId: feature.id,
        },
      })
    }
  }, [incrementSeenFeatureCount, feature])

  /* hooks cannot be conditional, so we handle the disabled logic at the end */
  if (!isFeatureEnabled) {
    return defaultValues
  }

  return {
    isOpen: isOpen || shouldOpenModal,
    feature,
    onOpen,
    onClose,
    onClick,
  }
}

export default useFeatureEducation
