const EN_US = {
  'connectedStoresAdminDomain.selectPrompt': 'Select an item on the left to view more details',
  'connectedStoresAdminDomain.submit': 'Save',
  'connectedStoresAdminDomain.saving': 'Saving...',
  'connectedStoresAdminDomain.reset': 'Reset',
  'connectedStoresAdminDomain.toastAction': 'Dismiss',

  'connectedStoresAdminDomain.caper.title': 'Caper',
  'connectedStoresAdminDomain.caper.pageTitle': 'Connected Stores Caper Page',
  'connectedStoresAdminDomain.caper.cartSubtitle': 'carts',
  'connectedStoresAdminDomain.caper.globalTitle': 'Global',
  'connectedStoresAdminDomain.caper.cityLabel': 'city',
  'connectedStoresAdminDomain.caper.configsTitle': 'Configs',
  'connectedStoresAdminDomain.caper.partnerTitle': 'Partner',
  'connectedStoresAdminDomain.caper.retailerTitle': 'Retailer',
  'connectedStoresAdminDomain.caper.retailerPartnersTitle': 'Retail Partners',
  'connectedStoresAdminDomain.caper.storeTitle': 'Store',
  'connectedStoresAdminDomain.caper.toastTitle': 'Config Updated',
  'connectedStoresAdminDomain.caper.toastError': 'Error updating config',
  'connectedStoresAdminDomain.caper.cancelAction': 'Cancel',
  'connectedStoresAdminDomain.caper.deleteAction': 'Delete',
  'connectedStoresAdminDomain.caper.deleteTitle': 'Delete Config',
  'connectedStoresAdminDomain.caper.save': 'Save',
  'connectedStoresAdminDomain.caper.environmentBannerProd': 'Production Caper Environment',
  'connectedStoresAdminDomain.caper.environmentBannerStg': 'Staging Caper Environment',

  'connectedStoresAdminDomain.shelfLabels.title': 'Carrot Tags',
  'connectedStoresAdminDomain.shelfLabels.stores': 'Stores',
  'connectedStoresAdminDomain.shelfLabels.connections': 'Connections',
  'connectedStoresAdminDomain.shelfLabels.syncStrategies': 'Sync Strategies',
  'connectedStoresAdminDomain.shelfLabels.search': 'Search by id or name',
  'connectedStoresAdminDomain.shelfLabels.location': 'Location',
  'connectedStoresAdminDomain.shelfLabels.config': 'Config',
  'connectedStoresAdminDomain.shelfLabels.id': 'ID',
  'connectedStoresAdminDomain.shelfLabels.name': 'Name',
  'connectedStoresAdminDomain.shelfLabels.vendor': 'Vendor',
  'connectedStoresAdminDomain.shelfLabels.store': 'Store',
  'connectedStoresAdminDomain.shelfLabels.connection': 'Connection',
  'connectedStoresAdminDomain.shelfLabels.syncStrategy': 'Sync Strategy',
  'connectedStoresAdminDomain.shelfLabels.retailerLocationId': 'Retailer Location ID',
  'connectedStoresAdminDomain.shelfLabels.vendorIdentifier': 'Vendor Identifier',
  'connectedStoresAdminDomain.shelfLabels.productCodeSource': 'Product Code Source',
  'connectedStoresAdminDomain.shelfLabels.productCodeSource.product_codes': 'Product Codes',
  'connectedStoresAdminDomain.shelfLabels.productCodeSource.retailer_reference_code':
    'Retailer Reference Code',
  'connectedStoresAdminDomain.shelfLabels.productCodeNormalization': 'Product Code Normalization',
  'connectedStoresAdminDomain.shelfLabels.productCodeNormalization.none': 'None',
  'connectedStoresAdminDomain.shelfLabels.productCodeNormalization.plu_or_gtin14': 'PLU/GTIN14',
  'connectedStoresAdminDomain.shelfLabels.key': 'Key',
  'connectedStoresAdminDomain.shelfLabels.customerCode': 'Customer Code',
  'connectedStoresAdminDomain.shelfLabels.username': 'Username',
  'connectedStoresAdminDomain.shelfLabels.password': 'Password',
  'connectedStoresAdminDomain.shelfLabels.clientId': 'Client ID',
  'connectedStoresAdminDomain.shelfLabels.clientSecret': 'Client Secret',
  'connectedStoresAdminDomain.shelfLabels.subdomain': 'Subdomain',
  'connectedStoresAdminDomain.shelfLabels.vendors.ses': 'SES (Vusion)',
  'connectedStoresAdminDomain.shelfLabels.vendors.pricer': 'Pricer',
  'connectedStoresAdminDomain.shelfLabels.vendors.hanshow_v1_5': 'Hanshow v1.5 (Aperion)',
  'connectedStoresAdminDomain.shelfLabels.vendors.hanshow_v2_4': 'Hanshow v2.4 (ALDI)',
  'connectedStoresAdminDomain.shelfLabels.putSuccess': 'Record saved',
  'connectedStoresAdminDomain.shelfLabels.putFailure': 'Could not save',
  'connectedStoresAdminDomain.shelfLabels.new': 'New',

  'connectedStoresAdminDomain.upos.title': 'UPOS',
  'connectedStoresAdminDomain.upos.status.healthy': 'Healthy',
  'connectedStoresAdminDomain.upos.status.unhealthy': 'Unhealthy',
  'connectedStoresAdminDomain.upos.status.undetermined': 'Not Implemented',
  'connectedStoresAdminDomain.upos.status.integration': 'Integration',
  'connectedStoresAdminDomain.upos.status.retailer': 'Retailer',
  'connectedStoresAdminDomain.upos.status.location': 'Location',
  'connectedStoresAdminDomain.upos.status.retailerLocationId': 'Retailer Location ID',
  'connectedStoresAdminDomain.upos.status.comment': 'Comment',
  'connectedStoresAdminDomain.upos.status.statusDescription': 'Status Description',
  'connectedStoresAdminDomain.upos.status.links': 'Links',
  'connectedStoresAdminDomain.upos.status.status': 'Status',
  'connectedStoresAdminDomain.upos.status.firstCheckedAt': 'First Checked At',
  'connectedStoresAdminDomain.upos.status.lastCheckedAt': 'Last Checked At',
  'connectedStoresAdminDomain.upos.status.healthySince': 'Healthy Since',
  'connectedStoresAdminDomain.upos.status.unhealthySince': 'Unhealthy Since',
  'connectedStoresAdminDomain.upos.status.reason': 'Reason',
  'connectedStoresAdminDomain.upos.status.debugInfo': 'Debug Info',
} as const

export default EN_US
