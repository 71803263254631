import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { AdminNavEntryPoint, AdminNavEntryPointV2 } from '@retailer-platform/dashboard/routing'
import { connectedStoresAdminRouter } from './routing/connectedStoresAdminRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import {
  caperAccessControl,
  shelfLabelsAccessControl,
  uposAccessControl,
} from './access-control/connectedStoresAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'connected-stores',
  totem: {
    entity: 'connected-stores-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    admin: [connectedStoresAdminRouter],
  },
  navigationEntries: [],
  adminNavigationEntries: [
    {
      attachTo: AdminNavEntryPoint.ConnectedStores,
      attachToV2: AdminNavEntryPointV2.Operations,
      subNavItems: [
        {
          route: 'app-admin-connected-stores-caper',
          labelId: 'connectedStoresAdminDomain.caper.title',
          accessControl: caperAccessControl,
        },
        {
          route: 'app-admin-connected-stores-shelf-labels',
          labelId: 'connectedStoresAdminDomain.shelfLabels.title',
          accessControl: shelfLabelsAccessControl,
        },
        {
          route: 'app-admin-connected-stores-upos',
          labelId: 'connectedStoresAdminDomain.upos.title',
          accessControl: uposAccessControl,
        },
      ],
    },
  ],
  // NOTE TO DEV: run `yarn ipp domain register <your-system-name>` to register your domain with Totem here
}

export default definition
