const EN_US = {
  //-- Common --//
  'storeInterventionsDomain.storeInterventions.yes': 'Yes',
  'storeInterventionsDomain.storeInterventions.no': 'No',
  'storeInterventionsDomain.storeInterventions.loading': 'Loading...',
  'storeInterventionsDomain.storeInterventions.help': 'Help',

  // IPP Navbar Labels
  'storeInterventionsDomain.navbar.requestList.label': 'Manage store intervention requests',
  'storeInterventionsDomain.navbar.createRequest.label': 'Submit store intervention request',

  // Titles
  'storeInterventionsDomain.requestList.manage.title': 'Manage Store Intervention Requests',
  'storeInterventionsDomain.requestList.title': 'Store Intervention Requests',
  'storeInterventionsDomain.createRequest.title': 'Submit Store Intervention Request',
  'storeInterventionsDomain.duplicateRequest.title': 'Duplicate Store Intervention Request',
  'storeInterventionsDomain.updateRequest.title': 'Update Store Intervention Request',

  // Help Modal
  'storeInterventionsDomain.storeInterventions.help.statuses.header': 'Requests Statuses',
  'storeInterventionsDomain.storeInterventions.help.statuses.content.differentStatusesTitle':
    'Requests can be in different statuses:',
  'storeInterventionsDomain.storeInterventions.help.statuses.content.differentStatusesExplanation':
    'Most requests will automatically handle their own status updates. For example, when you create an intervention for a request, the status of the request will automatically be updated to "Intervention Created". Once the intervention starts, it will move to "Intervention In Progress". And once it completes, it will be "Intervention Completed".',
  'storeInterventionsDomain.storeInterventions.help.statuses.content.differentStatusesManualCases':
    "Only store closure requests have automation around them. Incorrect store hour requests need to be manually updated. Also, if a request needs to be marked as expired, won't do, or duplicate, that also needs to be manually done.",
  'storeInterventionsDomain.storeInterventions.help.assigning.header': 'Assigning Requests',
  'storeInterventionsDomain.storeInterventions.help.assigning.explanation':
    'When a new request is created, it will be unassigned. You can assign a request to yourself by going to the request details. However, this is typically not necessary, and should mostly be used for assigning requests to other users, as requests will be auto-assigned to you if you action on them or modify them.',

  // Environment Message
  'storeInterventionsDomain.header.stagingEnvText':
    'You are on the staging environment. Changes here will have no impact to live systems.',

  // Side Panel
  'storeInterventionsDomain.sidePanel.done.label': 'Done',
  'storeInterventionsDomain.sidePanel.close.label': 'Close',

  // Request Status
  'storeInterventionsDomain.requestStatus.newRequest': 'New',
  'storeInterventionsDomain.requestStatus.wontDo': "Won't Do",
  'storeInterventionsDomain.requestStatus.interventionInProgress': 'Intervention In Progress',
  'storeInterventionsDomain.requestStatus.interventionCreated': 'Intervention Created',
  'storeInterventionsDomain.requestStatus.interventionCompleted': 'Intervention Completed',
  'storeInterventionsDomain.requestStatus.duplicate': 'Duplicate',
  'storeInterventionsDomain.requestStatus.expired': 'Expired',
  'storeInterventionsDomain.requestStatus.unspecified': 'Unspecified',

  // Request Reasons
  'storeInterventionsDomain.requestReason.adminConfigurationIssue': 'Admin Configuration Issue',
  'storeInterventionsDomain.requestReason.bdWhlRequest': 'BD WHL Request',
  'storeInterventionsDomain.requestReason.cityEvent': 'City Event',
  'storeInterventionsDomain.requestReason.crtIncident': 'CRT Incident',
  'storeInterventionsDomain.requestReason.emergencyClosure': 'Emergency Closure',
  'storeInterventionsDomain.requestReason.maintenanceClosure': 'Maintenance Closure',
  'storeInterventionsDomain.requestReason.posOutageHours': 'POS Outage',
  'storeInterventionsDomain.requestReason.powerOutage': 'Power Outage',
  'storeInterventionsDomain.requestReason.requestReasonUnspecified': 'Request Reason Unspecified',
  'storeInterventionsDomain.requestReason.temporaryStoreClosure': 'Temporary Store Closure',
  'storeInterventionsDomain.requestReason.whlIssuesExtremeWeather': 'WHL Issues - Extreme Weather',
  'storeInterventionsDomain.requestReason.incorrectStoreHours': 'Incorrect Store Hours',
  // Future Work: Reopen store
  // 'storeInterventionsDomain.requestReason.reopenStore': 'Re-open Store,

  // Priority Levels
  'storeInterventionsDomain.requestPriority.priority': 'Priority: {priorityLevel}',
  'storeInterventionsDomain.requestPriority.unspecified': 'Unspecified',
  'storeInterventionsDomain.requestPriority.crtRequest': '0',
  'storeInterventionsDomain.requestPriority.tempStoreClosure': '1',

  // Ignore Request Modal
  'storeInterventionsDomain.bulkUpdateStatusModal.openModalButton.label': 'Update Status',
  'storeInterventionsDomain.bulkUpdateStatusModal.title': 'Update Request Status',
  'storeInterventionsDomain.bulkUpdateStatusModal.requestId.label': 'Request IDs:',
  'storeInterventionsDomain.bulkUpdateStatusModal.selectReason.placeholder': 'Select reason',
  'storeInterventionsDomain.bulkUpdateStatusModal.notes.label': 'Notes',
  'storeInterventionsDomain.bulkUpdateStatusModal.notes.optional.label': 'optional',
  'storeInterventionsDomain.bulkUpdateStatusModal.notes.placeholder':
    'Add in additional details about this request...',
  'storeInterventionsDomain.bulkUpdateStatusModal.notes.characterLimit.label':
    '1000 characters max',
  'storeInterventionsDomain.bulkUpdateStatusModal.cancel.button.label': 'Cancel',
  'storeInterventionsDomain.bulkUpdateStatusModal.apply.button.label': 'Apply',
  'storeInterventionsDomain.bulkUpdateStatusModal.reasons.duplicate.label': 'Duplicate request',
  'storeInterventionsDomain.bulkUpdateStatusModal.reasons.wontDo.label': "Won't do",
  'storeInterventionsDomain.bulkUpdateStatusModal.reasons.expired.label': 'Expired request',
  'storeInterventionsDomain.bulkUpdateStatusModal.reasons.interventionInProgress.label':
    'Intervention in progress',
  'storeInterventionsDomain.bulkUpdateStatusModal.reasons.interventionCompleted.label':
    'Intervention complete',
  'storeInterventionsDomain.bulkUpdateStatusModal.reasons.interventionCreated.label':
    'Intervention created',
  'storeInterventionsDomain.bulkUpdateStatusModal.reasons.newRequest.label': 'New request',
  'storeInterventionsDomain.bulkUpdateStatusModal.errors.missingReason': 'Reason must be selected',
  'storeInterventionsDomain.bulkUpdateStatusModal.successMessage':
    'The requests have been successfully updated.',

  // Delete Request Modal
  'storeInterventionsDomain.deleteRequestModal.openModalButton.label': 'Delete',
  'storeInterventionsDomain.deleteRequestModal.title': 'Delete Request',
  'storeInterventionsDomain.deleteRequestModal.header':
    'Do you want to delete the following requests?',
  'storeInterventionsDomain.deleteRequestModal.requestId.label': 'Request ID:',
  'storeInterventionsDomain.deleteRequestModal.changeType.label': 'Change Type:',
  'storeInterventionsDomain.deleteRequestModal.retailersAffected.label': 'Retailers Affected:',
  'storeInterventionsDomain.deleteRequestModal.warning': 'This action cannot be undone.',
  'storeInterventionsDomain.deleteRequestModal.cancel.button.label': 'Cancel',
  'storeInterventionsDomain.deleteRequestModal.delete.button.label': 'Delete',
  'storeInterventionsDomain.deleteRequestModal.successMessage':
    'The request(s) have been successfully deleted.',

  // Delete Request Note Modal
  'storeInterventionsDomain.deleteRequestNoteModal.title': 'Delete Note',
  'storeInterventionsDomain.deleteRequestNoteModal.header': 'Do you want to delete this note?',
  'storeInterventionsDomain.deleteRequestNoteModal.warning': 'This action cannot be undone.',
  'storeInterventionsDomain.deleteRequestNoteModal.cancel.button.label': 'Cancel',
  'storeInterventionsDomain.deleteRequestNoteModal.delete.button.label': 'Delete',
  'storeInterventionsDomain.deleteRequestNoteModal.successMessage':
    'The note has been successfully deleted.',

  // Edit Request Note Modal
  'storeInterventionsDomain.editRequestNoteModal.title': 'Edit Note',
  'storeInterventionsDomain.editRequestNoteModal.header': 'Edit the note details below:',
  'storeInterventionsDomain.editRequestNoteModal.cancel.button.label': 'Cancel',
  'storeInterventionsDomain.editRequestNoteModal.edit.button.label': 'Save',
  'storeInterventionsDomain.editRequestNoteModal.successMessage':
    'The note has been successfully updated.',

  //-- Store Intervention Create Request Page --//
  'storeInterventionsDomain.createRequest.form.testingAlert':
    'This feature is currently in testing. Requests submitted now will not be actioned.',

  // Form Actions
  'storeInterventionsDomain.createRequest.form.actions.cancel': 'Cancel',
  'storeInterventionsDomain.createRequest.form.actions.next': 'Next',
  'storeInterventionsDomain.createRequest.form.actions.back': 'Back',
  'storeInterventionsDomain.createRequest.form.actions.review': 'Review',
  'storeInterventionsDomain.createRequest.form.actions.submit': 'Submit',

  // Header card
  'storeInterventionsDomain.createRequest.headerCard.title': 'Request for Store Intervention',
  'storeInterventionsDomain.createRequest.headerCard.description':
    'Start by searching for a store by the location ID or retailer name and store number.',

  // Nav
  'storeInterventionsDomain.createRequest.pageHeader': 'Intervention Request',
  'storeInterventionsDomain.createRequest.nav.step1': 'Step 1: Search for Store',
  'storeInterventionsDomain.createRequest.nav.step2': 'Step 2: Request Details',
  'storeInterventionsDomain.createRequest.nav.step3': 'Step 3: Review & Submit',

  // Cancel Modal
  'storeInterventionsDomain.createRequest.cancelModal.title': 'Are you sure you want to cancel?',
  'storeInterventionsDomain.createRequest.cancelModal.description': 'All changes will be lost.',
  'storeInterventionsDomain.createRequest.cancelModal.cancel': 'Cancel',
  'storeInterventionsDomain.createRequest.cancelModal.goBack': 'Go back',

  // Confirm Modal
  'storeInterventionsDomain.createRequest.confirmModal.openModalButton.label': 'Create Request',
  'storeInterventionsDomain.createRequest.confirmModal.title': 'Create Request',
  'storeInterventionsDomain.createRequest.confirmModal.description':
    'The following retailers will be affected:',
  'storeInterventionsDomain.createRequest.confirmModal.stores.label': 'stores',
  'storeInterventionsDomain.createRequest.confirmModal.cancel': 'Go back',
  'storeInterventionsDomain.createRequest.confirmModal.submit': 'Submit',
  'storeInterventionsDomain.createRequest.confirmModal.successMessage':
    'Request(s) created successfully.',
  'storeInterventionsDomain.createRequest.confirmModal.errorMessage':
    'An error occurred while processing the request.',

  // Confirm Modal - Update Request
  'storeInterventionsDomain.requestForm.confirmModal.updateRequest.openModalButton.label':
    'Update Request',
  'storeInterventionsDomain.requestForm.confirmModal.updateRequest.title': 'Update Request',
  'storeInterventionsDomain.requestForm.confirmModal.updateRequest.successMessage':
    'Request updated successfully.',

  // Form Errors
  'storeInterventionsDomain.createRequest.formErrors.warehouseLocations.required':
    'At least one warehouse location must be selected',
  'storeInterventionsDomain.createRequest.formErrors.changeType.required':
    'Change type must be selected',
  'storeInterventionsDomain.createRequest.formErrors.priority.required':
    'Priority level must be selected',
  'storeInterventionsDomain.createRequest.formErrors.startDate.required': 'Start date is required',
  'storeInterventionsDomain.createRequest.formErrors.startTime.required': 'Start time is required',
  'storeInterventionsDomain.createRequest.formErrors.endDate.required': 'End date is required',
  'storeInterventionsDomain.createRequest.formErrors.endTime.required': 'End time is required',
  'storeInterventionsDomain.createRequest.formErrors.endTimeBeforeStartTime':
    'The end date and time must be after the start date and time',

  // Step One
  'storeInterventionsDomain.createRequest.searchForStore.warehouseLocationId': 'WHL ID',
  'storeInterventionsDomain.createRequest.searchForStore.retailerNameAndLocationCode':
    'Retailer Name & Location Code',
  'storeInterventionsDomain.createRequest.searchForStore.retailer': 'Retailer',
  'storeInterventionsDomain.createRequest.searchForStore.retailerNoOptionsMessage':
    'Type to start searching retailers',
  'storeInterventionsDomain.createRequest.searchForStore.warehouseLocationsSelected':
    'warehouse location(s) selected',
  'storeInterventionsDomain.createRequest.searchForStore.clearSelection': 'Clear selection',

  // Step Two
  'storeInterventionsDomain.createRequest.requestDetails.header': 'Request Details',
  'storeInterventionsDomain.createRequest.requestDetails.retailersAffected.label':
    'Retailers affected:',
  'storeInterventionsDomain.createRequest.requestDetails.changeType.label': 'Change Type',
  'storeInterventionsDomain.createRequest.requestDetails.changeType.placeholder': 'Select',
  'storeInterventionsDomain.createRequest.requestDetails.priority.label': 'Priority',
  'storeInterventionsDomain.createRequest.requestDetails.priority.placeholder': 'Select',
  'storeInterventionsDomain.createRequest.requestDetails.notes.label': 'Add note',
  'storeInterventionsDomain.createRequest.requestDetails.notes.submitLabel': 'Submit',
  'storeInterventionsDomain.createRequest.requestDetails.notes.tagUsers.placeholder':
    'Tag users by email',
  'storeInterventionsDomain.createRequest.requestDetails.notes.tagUsers.noOptionsMessage':
    'No users found for given input',
  'storeInterventionsDomain.createRequest.retailerDetailsSection.header':
    '{retailerName} change request details',
  'storeInterventionsDomain.createRequest.retailerDetailsSection.storeCount.single':
    'Selected - 1 store',
  'storeInterventionsDomain.createRequest.retailerDetailsSection.storeCount.multiple':
    'Selected - {storeCount} stores',
  'storeInterventionsDomain.createRequest.retailerDetailsSection.retailerId.label': 'Retailer ID',
  'storeInterventionsDomain.createRequest.retailerDetailsSection.useFirstRetailerSettings.label':
    'Use first retailer settings',
  'storeInterventionsDomain.createRequest.requestDetails.tempClosureSection.timeInfo':
    'Times are applied in the timezone of each warehouse location. Selecting 8:00 AM will result in 8:00 AM local time. Selecting a different change type will update the start and end times accordingly.',
  'storeInterventionsDomain.createRequest.requestDetails.tempClosureSection.startDate.label':
    'Start Date',
  'storeInterventionsDomain.createRequest.requestDetails.tempClosureSection.startTime.label':
    'Start Time',
  'storeInterventionsDomain.createRequest.requestDetails.tempClosureSection.endDate.label':
    'End Date',
  'storeInterventionsDomain.createRequest.requestDetails.tempClosureSection.endTime.label':
    'End Time',
  'storeInterventionsDomain.createRequest.requestDetails.tempClosureSection.duration.label':
    'Duration',
  'storeInterventionsDomain.createRequest.requestDetails.tempClosureSection.configuration.header':
    'Configuration',
  'storeInterventionsDomain.createRequest.requestDetails.tempClosureSection.disablePickup.label':
    'Turn pick-up off',
  'storeInterventionsDomain.createRequest.requestDetails.tempClosureSection.disablePickup.tooltip':
    'Customers will not be able to place orders for pick up',
  'storeInterventionsDomain.createRequest.requestDetails.tempClosureSection.disableDelivery.label':
    'Turn delivery off',
  'storeInterventionsDomain.createRequest.requestDetails.tempClosureSection.disableDelivery.tooltip':
    'Customers will not be able to place orders for delivery',
  'storeInterventionsDomain.createRequest.requestDetails.tempClosureSection.disableBatching.label':
    'Turn batching off',
  'storeInterventionsDomain.createRequest.requestDetails.tempClosureSection.disableBatching.tooltip':
    'Orders can still be placed by customers, but will not be batched to shoppers',
  'storeInterventionsDomain.createRequest.requestDetails.incorrectStoreHoursSection.description':
    'List out the correct hours in the area below',
  'storeInterventionsDomain.createRequest.requestDetails.incorrectStoreHoursSection.requiredError':
    'Please enter details about your request',
  'storeInterventionsDomain.createRequest.requestDetails.issueLinkSection.header': 'Issue link',
  'storeInterventionsDomain.createRequest.requestDetails.issueLinkSection.optional.label':
    'Optional',

  // Step Three
  'storeInterventionsDomain.createRequest.review.header': 'Review Request Details',
  'storeInterventionsDomain.createRequest.review.commonDetails.retailersAffected.label':
    ' Retailers affected',
  'storeInterventionsDomain.createRequest.review.commonDetails.changeType.label': 'Change Type',
  'storeInterventionsDomain.createRequest.review.commonDetails.priority.label': 'Priority',
  'storeInterventionsDomain.createRequest.review.retailerDetails.start.label': 'Start',
  'storeInterventionsDomain.createRequest.review.retailerDetails.end.label': 'End',
  'storeInterventionsDomain.createRequest.review.retailerDetails.configuration.label':
    'Configuration',
  'storeInterventionsDomain.createRequest.review.retailerDetails.configuration.noConfigurationSelected':
    'No configuration selected',
  'storeInterventionsDomain.createRequest.review.retailerDetails.configuration.turnPickupOff':
    'Turn pick-up off',
  'storeInterventionsDomain.createRequest.review.retailerDetails.configuration.turnDeliveryOff':
    'Turn delivery off',
  'storeInterventionsDomain.createRequest.review.retailerDetails.configuration.turnBatchingOff':
    'Turn batching off',
  'storeInterventionsDomain.createRequest.review.retailerDetails.retailerId.label': 'Retailer ID',
  'storeInterventionsDomain.createRequest.review.retailerDetails.issueLink.label': 'Issue link',
  'storeInterventionsDomain.createRequest.review.retailerDetails.warehouseLocationsGrid.header':
    'Selected - {storeCount} Stores',
  'storeInterventionsDomain.createRequest.review.retailerDetails.additionalRequestContext.label':
    'Request information',

  // Step Four
  'storeInterventionsDomain.createRequest.requestCreatedSuccessfully.header':
    'Request(s) successfully created!',
  'storeInterventionsDomain.createRequest.requestCreatedSuccessfully.changeType.label':
    'Change type:',
  'storeInterventionsDomain.createRequest.requestCreatedSuccessfully.retailersAffected.label':
    'Retailers affected:',
  'storeInterventionsDomain.createRequest.requestCreatedSuccessfully.createAnother.label':
    'Create another',

  // Duplicate Checks
  'storeInterventionsDomain.duplicateChecks.warning.duplicateRequestsFound':
    'Duplicates Detected! Some warehouse locations are already included in another active request.',
  'storeInterventionsDomain.duplicateChecks.warning.locationIncludedInOpenRequest':
    'Location is already included in open request ID: {duplicateRequestId} ({duplicateRequestReason})',
  'storeInterventionsDomain.duplicateChecks.warning.locationIncludedInMultipleOpenRequests':
    'Location is already included in {duplicateRequestCount} other open requests.',
  'storeInterventionsDomain.duplicateChecks.warning.clickToOpen': '(Click to open in new window)',
  'storeInterventionsDomain.duplicateChecks.warning.clickToViewList':
    '(Click to view the list of requests)',
  'storeInterventionsDomain.duplicateChecks.warning.requestListModal.title':
    'Requests with the selected location',
  'storeInterventionsDomain.duplicateChecks.warning.requestListModal.header.id': 'Request ID',
  'storeInterventionsDomain.duplicateChecks.warning.requestListModal.header.reason':
    'Request Reason ',
  'storeInterventionsDomain.duplicateChecks.warning.requestListModal.item': 'ID:',

  // Locations grid
  'storeInterventionsDomain.createRequest.locationsGrid.loadingRetailerName':
    'Loading Retailer Name...',
  'storeInterventionsDomain.createRequest.locationsGrid.tab.label.whlDetails': 'WHL Details',
  'storeInterventionsDomain.createRequest.locationsGrid.tab.label.storeHours': 'Store Hours',
  'storeInterventionsDomain.createRequest.locationsGrid.header.whl': 'ID & Name',
  'storeInterventionsDomain.createRequest.locationsGrid.header.storeId': 'Store ID',
  'storeInterventionsDomain.createRequest.locationsGrid.header.pickup': 'Has Pickup?',
  'storeInterventionsDomain.createRequest.locationsGrid.header.partnerPick': 'Has Partner Pick?',
  'storeInterventionsDomain.createRequest.locationsGrid.header.storeIsOpen': 'Store is Open?',
  'storeInterventionsDomain.createRequest.locationsGrid.header.physicalZone': 'Physical Zone',
  'storeInterventionsDomain.createRequest.locationsGrid.header.timeZone': 'Time Zone',
  'storeInterventionsDomain.createRequest.locationsGrid.header.hours': 'Hours',
  'storeInterventionsDomain.createRequest.locationsGrid.header.historicalRequests':
    'Events & Past Requests',
  'storeInterventionsDomain.createRequest.locationsGrid.header.transactions': 'Transactions',
  'storeInterventionsDomain.createRequest.locationsGrid.header.interventions': 'Events',
  'storeInterventionsDomain.createRequest.locationsGrid.hours.loading': 'Loading...',
  'storeInterventionsDomain.createRequest.locationsGrid.hours.storeNotAvailable':
    'Store hours not available',
  'storeInterventionsDomain.createRequest.locationsGrid.hours.storeNotLaunched':
    'Store has not launched yet',
  'storeInterventionsDomain.createRequest.locationsGrid.hours.storeHoursOpen24': 'Open 24 hours',
  'storeInterventionsDomain.createRequest.locationsGrid.hours.storeHoursClosed': 'Closed',
  'storeInterventionsDomain.createRequest.locationsGrid.hours.hoursNotAvailable':
    'Hours not available',
  'storeInterventionsDomain.createRequest.locationsGrid.hours.error': 'Error loading store hours',
  'storeInterventionsDomain.createRequest.locationsGrid.historicalRequests.loading': 'Loading...',
  'storeInterventionsDomain.createRequest.locationsGrid.historicalRequests.error':
    'Error loading historical requests',
  'storeInterventionsDomain.createRequest.locationsGrid.historicalRequests.notFound':
    'No historical requests found',
  'storeInterventionsDomain.createRequest.locationsGrid.transactions.loading': 'Loading...',
  'storeInterventionsDomain.createRequest.locationsGrid.transactions.error':
    'Error loading transaction data',
  'storeInterventionsDomain.createRequest.locationsGrid.transactions.notFound':
    'No transactions in the last hour',
  'storeInterventionsDomain.createRequest.locationsGrid.transactions.label':
    'Transaction data for the last hour',
  'storeInterventionsDomain.createRequest.locationsGrid.transactions.successfulTransactionsLabel':
    'Successful transactions',
  'storeInterventionsDomain.createRequest.locationsGrid.transactions.unsuccessfulTransactionsLabel':
    'Unsuccessful transactions',
  'storeInterventionsDomain.createRequest.locationsGrid.transactions.lastSuccessfulTransactionLabel':
    'Last successful transaction',
  'storeInterventionsDomain.createRequest.locationsGrid.transactions.lastUnsuccessfulTransactionLabel':
    'Last unsuccessful transaction',
  'storeInterventionsDomain.createRequest.locationsGrid.transactions.none': 'None',
  'storeInterventionsDomain.createRequest.locationsGrid.transactions.minutesAgoTransaction':
    'minutes ago',
  'storeInterventionsDomain.createRequest.locationsGrid.storeIsOpen.error':
    'An error occurred while fetching partner configurations',
  'storeInterventionsDomain.createRequest.locationsGrid.removeSelection': 'Remove selection',

  //-- Duplicate Request Page --//
  'storeInterventionsDomain.duplicateRequest.pageHeader': 'Duplicate Store Intervention Request',
  'storeInterventionsDomain.duplicateRequest.errorLoadingRequest':
    'There was an error loading the request data',
  'storeInterventionsDomain.duplicateRequest.notAllowed':
    'This type of request cannot be duplicated. Please create a new request.',

  //-- Update Request Page --//
  'storeInterventionsDomain.updateRequest.pageHeader': 'Update Store Intervention Request',
  'storeInterventionsDomain.updateRequest.errorLoadingRequest':
    'There was an error loading the request data',
  'storeInterventionsDomain.updateRequest.notAllowed':
    'This type of request is not editable. Please update the status or delete it instead.',
  'storeInterventionsDomain.updateRequest.storeSelection.alert':
    'When updating a request, selected warehouse locations must belong to the original retailer in the request.',
  'storeInterventionsDomain.updateRequest.storeSelection.retailerConstraintError':
    'The following selected WHL IDs do not belong to the original retailer (ID: {retailerIdConstraint}) in the request: ',

  //-- Store Interventions Requests List Page --//

  // Request Insights
  'storeInterventionsDomain.requestInsight.title': 'Request Insights',
  'storeInterventionsDomain.requestInsight.description': 'Showing insights for the last 24 hours',

  // Requests Grid
  'storeInterventionsDomain.requestsList.grid.requestId': 'Request ID',
  'storeInterventionsDomain.requestsList.grid.retailerName': 'Retailer Name',
  'storeInterventionsDomain.requestsList.grid.retailerName.loading': 'Loading Retailer Name...',
  'storeInterventionsDomain.requestsList.grid.warehouseLocations': 'Warehouse Locations',
  'storeInterventionsDomain.requestsList.grid.context': 'Context',
  'storeInterventionsDomain.requestsList.grid.dateCreated': 'Date Created',
  'storeInterventionsDomain.requestsList.grid.dateUpdated': 'Date Last Updated',
  'storeInterventionsDomain.requestsList.grid.priority': 'Priority',
  'storeInterventionsDomain.requestsList.grid.reason': 'Reason',
  'storeInterventionsDomain.requestsList.grid.start': 'Start',
  'storeInterventionsDomain.requestsList.grid.end': 'End',
  'storeInterventionsDomain.requestsList.grid.status': 'Status',
  'storeInterventionsDomain.requestsList.grid.assignedTo': 'Assigned To',
  'storeInterventionsDomain.requestsList.grid.intervention': 'Intervention',
  'storeInterventionsDomain.requestsList.grid.loading': 'Loading...',
  'storeInterventionsDomain.requestsList.grid.unassigned': 'Unassigned',
  'storeInterventionsDomain.requestsList.grid.requestCount': 'Requests ({requestCount})',
  'storeInterventionsDomain.requestsList.grid.noResults':
    'No request found. Try changing or removing some of the filters.',
  'storeInterventionsDomain.requestsList.grid.allRecordsOnPageSelected':
    'All {numberOfRecords} requests on this page have been selected',
  'storeInterventionsDomain.requestsList.grid.numberOfRecordsSelected':
    '{numberOfRecords} request(s) selected',
  'storeInterventionsDomain.requestsList.grid.unselectAll': 'Unselect all',
  'storeInterventionsDomain.requestsList.grid.edit': 'Edit',
  'storeInterventionsDomain.requestsList.grid.ignore': 'Ignore',
  'storeInterventionsDomain.requestsList.grid.delete': 'Delete',
  'storeInterventionsDomain.requestsList.grid.createIntervention': 'Create Intervention',
  'storeInterventionsDomain.requestsList.grid.pendingRequestsAlert.alert.plural':
    'There are {pendingRequestsCount} requests awaiting action.',
  'storeInterventionsDomain.requestsList.grid.pendingRequestsAlert.alert.singular':
    'There is 1 request awaiting action.',
  'storeInterventionsDomain.requestsList.grid.pendingRequestsAlert.filterButton.label':
    'Filter for new requests',
  'storeInterventionsDomain.requestsList.grid.whlTooltip.countMessage.plural':
    'Warehouse Locations ({count})',
  'storeInterventionsDomain.requestsList.grid.whlTooltip.countMessage.singular':
    'Warehouse Location (ID: {warehouseLocationId})',
  'storeInterventionsDomain.requestsList.grid.whlTooltip.cutoffMessage':
    '{remainingLocationCount} more...',
  'storeInterventionsDomain.requestsList.grid.filters.numberOfFiltersSelected':
    '{numberOfFiltersSelected} filter(s) selected',
  'storeInterventionsDomain.requestsList.grid.filters.clearAllFilters': 'Clear all filters',

  // Requests list refresh banner
  'storeInterventionsDomain.requestsList.refreshBanner.newDataAvailable':
    'New request data is available.',
  'storeInterventionsDomain.requestsList.refreshBanner.refresh': 'Refresh',

  // Request Actions Button
  'storeInterventionsDomain.requestsList.actionsButton.createIntervention': 'Create Intervention',
  'storeInterventionsDomain.requestsList.actionsButton.updateRequest': 'Edit Request',
  'storeInterventionsDomain.requestsList.actionsButton.duplicateRequest': 'Duplicate Request',
  'storeInterventionsDomain.requestsList.actionsButton.ignoreRequest': 'Ignore Request',
  'storeInterventionsDomain.requestsList.actionsButton.deleteRequest': 'Delete Request',

  // Filter Panel
  'storeInterventionsDomain.requestsList.filterPanel.buttonLabel': 'Filters',
  'storeInterventionsDomain.requestsList.filterPanel.header': 'Filters',
  'storeInterventionsDomain.requestsList.filterPanel.requests': 'Requests',
  'storeInterventionsDomain.requestsList.filterPanel.close': 'Close',
  'storeInterventionsDomain.requestsList.filterPanel.done': 'Done',
  'storeInterventionsDomain.requestsList.filterPanel.warehouse': 'Warehouse',
  'storeInterventionsDomain.requestsList.filterPanel.noOptionsMessage': 'Type to start searching',
  'storeInterventionsDomain.requestsList.filterPanel.address': 'Address',
  'storeInterventionsDomain.requestsList.filterPanel.createdDate': 'Created On',
  'storeInterventionsDomain.requestsList.filterPanel.requester': 'Requester',
  'storeInterventionsDomain.requestsList.filterPanel.requestId': 'Request ID',
  'storeInterventionsDomain.requestsList.filterPanel.requestReason': 'Reason',
  'storeInterventionsDomain.requestsList.filterPanel.priority': 'Priority',
  'storeInterventionsDomain.requestsList.filterPanel.zone': 'Zone',
  'storeInterventionsDomain.requestsList.filterPanel.stateOfWhl': 'State of WHL',
  'storeInterventionsDomain.requestsList.filterPanel.status': 'Status',
  'storeInterventionsDomain.requestsList.filterPanel.warehouseLocation.label': 'Warehouse Location',

  // Request Details Panel
  'storeInterventionsDomain.requestDetailsPanel.header': 'Request Details',
  'storeInterventionsDomain.requestDetailsPanel.edit.button.label': 'Edit',
  'storeInterventionsDomain.requestDetailsPanel.duplicate.button.label': 'Duplicate',
  'storeInterventionsDomain.requestDetailsPanel.changeType.label': 'Change Type',
  'storeInterventionsDomain.requestDetailsPanel.requestId.label': 'Request ID',
  'storeInterventionsDomain.requestDetailsPanel.duplicateRequest.label': 'Duplicate Request(s)',
  'storeInterventionsDomain.requestDetailsPanel.viewNotes.label': 'View Notes ({noteCount})',
  'storeInterventionsDomain.requestDetailsPanel.notes.header': 'Request Notes',
  'storeInterventionsDomain.requestDetailsPanel.notes.edit.label': 'Edit Note',
  'storeInterventionsDomain.requestDetailsPanel.notes.delete.label': 'Delete Note',
  'storeInterventionsDomain.requestDetailsPanel.notes.requestId.label': 'Request ID: {requestId}',
  'storeInterventionsDomain.requestDetailsPanel.retailerSection.singleStore.label':
    '{retailerName} - 1 Store (ID: {storeId})',
  'storeInterventionsDomain.requestDetailsPanel.retailerSection.multipleStores.label':
    '{retailerName} - {storeCount} Store(s)',
  'storeInterventionsDomain.requestDetailsPanel.viewAllStores.label': 'View all stores',
  'storeInterventionsDomain.requestDetailsPanel.startDate.label': 'Start Date',
  'storeInterventionsDomain.requestDetailsPanel.endDate.label': 'End Date',
  'storeInterventionsDomain.requestDetailsPanel.startTime.label': 'Start Time',
  'storeInterventionsDomain.requestDetailsPanel.endTime.label': 'End Time',
  'storeInterventionsDomain.requestDetailsPanel.turnPickupOff.label': 'Turn pick-up off',
  'storeInterventionsDomain.requestDetailsPanel.turnDeliveryOff.label': 'Turn delivery off',
  'storeInterventionsDomain.requestDetailsPanel.turnBatchingOff.label': 'Turn batching off',
  'storeInterventionsDomain.requestDetailsPanel.footer.editInfo.button.label': 'Edit Info',
  'storeInterventionsDomain.requestDetailsPanel.footer.intervene.button.label': 'Intervene',
  'storeInterventionsDomain.requestDetailsPanel.footer.backToDetails.button.label':
    'Back to Details',
  'storeInterventionsDomain.requestDetailsPanel.yes.label': 'Yes',
  'storeInterventionsDomain.requestDetailsPanel.no.label': 'No',
  'storeInterventionsDomain.requestDetailsPanel.loadingAccount.label': 'Loading...',
  'storeInterventionsDomain.requestDetailsPanel.storeList.header': 'Stores',
  'storeInterventionsDomain.requestDetailsPanel.storeList.requestId.label':
    'Request ID: {requestId}',
  'storeInterventionsDomain.requestDetailsPanel.storeList.whlId.label': 'WHL ID:',
  'storeInterventionsDomain.requestDetailsPanel.storeList.storeId.label': 'Store ID:',
  'storeInterventionsDomain.requestDetailsPanel.requestor.label': 'Requestor',
  'storeInterventionsDomain.requestDetailsPanel.issueLink.label': 'Issue link',

  //-- Filters --//
  'storeInterventionsDomain.filters.search': 'Search',
  'storeInterventionsDomain.filters.dateRange': 'Date range',
  'storeInterventionsDomain.filters.noDateSelected': 'No date selected',

  // Warehouse Location Search
  'storeInterventionsDomain.filters.warehouseLocationSearch.error.errorFetchingWarehouseLocations':
    'An error occurred while fetching the requested warehouse locations.',
  'storeInterventionsDomain.filters.warehouseLocationSearch.notification.warehouseLocationsNotFound':
    'No warehouse locations found for the following requested WHL IDs:',
  'storeInterventionsDomain.filters.warehouseLocationByLocationCodeSearch.notification.warehouseLocationsNotFound':
    'No warehouse locations found for the following requested location codes:',

  //-- Request Details Page --//
  'storeInterventionsDomain.requestDetails.title': 'Request Details',
  'storeInterventionsDomain.requestDetails.pageHeader': 'Request Details',

  // Request summary card
  'storeInterventionsDomain.requestDetails.summaryCard.requestId.label': 'Request ID',
  'storeInterventionsDomain.requestDetails.summaryCard.liveOpsEscalation.label':
    'LiveOps Escalation:',
  'storeInterventionsDomain.requestDetails.summaryCard.storesAffected.label': 'Stores affected:',
  'storeInterventionsDomain.requestDetails.summaryCard.requestor.label': 'Requested by:',

  // Request notes card
  'storeInterventionsDomain.requestDetails.notesCard.header': 'Notes ({notesCount})',
  'storeInterventionsDomain.requestDetails.notesCard.subHeader': 'Notes',
  'storeInterventionsDomain.requestDetails.notesCard.author.label': 'By:',

  // Retailer details card
  'storeInterventionsDomain.requestDetails.retailerDetailsCard.header':
    '{retailerName} change request details',
  'storeInterventionsDomain.requestDetails.retailerDetailsCard.fallbackHeader':
    'Retailer change request details',
  'storeInterventionsDomain.requestDetails.retailerDetailsCard.retailerId.label': 'Retailer ID',
  'storeInterventionsDomain.requestDetails.retailerDetailsCard.configuration.label':
    'Configuration',
  'storeInterventionsDomain.requestDetails.retailerDetailsCard.startDate.label': 'Start Date',
  'storeInterventionsDomain.requestDetails.retailerDetailsCard.endDate.label': 'End Date',
  'storeInterventionsDomain.requestDetails.retailerDetailsCard.startTime.label': 'Start Time',
  'storeInterventionsDomain.requestDetails.retailerDetailsCard.endTime.label': 'End Time',
  'storeInterventionsDomain.requestDetails.retailerDetailsCard.turnPickupOff.label':
    'Turn pick-up off',
  'storeInterventionsDomain.requestDetails.retailerDetailsCard.turnDeliveryOff.label':
    'Turn delivery off',
  'storeInterventionsDomain.requestDetails.retailerDetailsCard.turnBatchingOff.label':
    'Turn batching off',
  'storeInterventionsDomain.requestDetails.retailerDetailsCard.yes.label': 'Yes',
  'storeInterventionsDomain.requestDetails.retailerDetailsCard.no.label': 'No',
  'storeInterventionsDomain.requestDetails.retailerDetailsCard.issueLink.label': 'Issue link',
  'storeInterventionsDomain.requestDetails.retailerDetailsCard.warehouseLocationGrid.header':
    'Selected - {storeCount} Stores',
  'storeInterventionsDomain.requestDetails.retailerDetailsCard.warehouseLocations.error':
    'An error occurred while fetching the affected warehouse locations.',

  // Request Details Footer
  'storeInterventionsDomain.requestDetails.footer.back.button.label': 'Back',
  'storeInterventionsDomain.requestDetails.footer.edit.button.label': 'Edit',
  'storeInterventionsDomain.requestDetails.footer.intervene.button.label': 'Intervene',

  // Async multi select component
  'asyncMultiSelect.applyPasteInstruction': 'Apply',
  'asyncMultiSelect.pasteIdsPlaceholder':
    'Optional: Enter comma separated record IDs (IDs are database IDs, not codes or names)',

  // Change status dropdown
  'storeInterventionsDomain.updateStatusDropdown.duplicateStatus.label': 'Duplicate request',
  'storeInterventionsDomain.updateStatusDropdown.expiredStatus.label': 'Expired request',
  'storeInterventionsDomain.updateStatusDropdown.interventionInProgressStatus.label':
    'Intervention in progress',
  'storeInterventionsDomain.updateStatusDropdown.interventionCompletedStatus.label':
    'Intervention completed',
  'storeInterventionsDomain.updateStatusDropdown.interventionCreatedStatus.label':
    'Intervention created',
  'storeInterventionsDomain.updateStatusDropdown.newRequestStatus.label': 'New request',
  'storeInterventionsDomain.updateStatusDropdown.wontDoStatus.label': "Won't do",
  'storeInterventionsDomain.updateStatusDropdown.placeholder': 'Select new status',
  'storeInterventionsDomain.updateStatusDropdown.submit.label': 'Update status',
  'storeInterventionsDomain.updateStatusDropdown.submit.success.label':
    'Status successfully updated',
  'storeInterventionsDomain.updateStatusDropdown.submit.failure.label':
    'Something went wrong - status not updated',
  'storeInterventionsDomain.updateAssignedToDropdown.placeholder': 'Select new assignee',
  'storeInterventionsDomain.updateAssignedToDropdown.submit.label': 'Update assignee',
  'storeInterventionsDomain.updateAssignedToDropdown.submit.success.label':
    'Assignee successfully updated',
  'storeInterventionsDomain.updateAssignedToDropdown.submit.failure.label':
    'Something went wrong - assignee not updated',

  'storeInterventionsDomain.additionalRequestContextDetails.incorrectHours.title':
    'Correct hours are:',
  'storeInterventionsDomain.additionalRequestContextDetails.generic.title': 'Additional context:',
  'storeInterventionsDomain.incorrectStoreHoursCta': 'Go to store hours',
} as const

export default EN_US
